import { useEffect, useState } from "react";
import { ImSpinner2 } from "react-icons/im";
import { toast } from "react-toastify";
import api from "../../../../api";
import { Link, useLocation } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { IoChevronBackSharp } from "react-icons/io5";
import { fetchData } from "../../../../GlobalFunctions";
import { RiSubtractFill } from "react-icons/ri";
import { IoMdAdd } from "react-icons/io";

function EditLevel({ setRefreshData }: any) {
  interface LevelData {
    id: string;
    name: string;
    price: number;
  }

  interface CoursesData {
    id: string;
    name: string;
  }

  const { state } = useLocation();

  const [name, setName] = useState("");
  const [price, setPrice] = useState<number | null>(0);

  const [levelId, setLevelId] = useState<string>(() => {
    const storedLevelId = window.localStorage.getItem("levelId") || "";
    return (state && state.level_id) || storedLevelId;
  });

  const [levelData, setLevelData] = useState<LevelData>();

  const [coursesData, setCoursesData] = useState<CoursesData[]>([]);
  const [selectedCourses, setSelectedCourses] = useState<string[]>([]);
  const [coursesSearchTerm, setCoursesSearchTerm] = useState("");

  const [tabChange, setTabChange] = useState("general");
  const [loading1, setLoading1] = useState(false);

  const handleTabClick = async (temp: any) => {
    setTabChange(temp);
  };

  useEffect(() => {
    if (price && price < 0) {
      setPrice(0);
    }
  }, [price]);

  useEffect(() => {
    if (tabChange === "general") {
      fetchData(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_LEVEL_BY_ID}${levelId}`,
        setLevelData
      );

      setCoursesData([]);
      setSelectedCourses([]);
    } else if (tabChange === "courses") {
      fetchData(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_LEVEL_COURSES_BY_ID}${levelId}`,
        setSelectedCourses
      );
      fetchData(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ALL_COURSES}`,
        setCoursesData
      );
    }
    setLoading1(false);
  }, [tabChange]);

  useEffect(() => {
    if (levelData) {
      setName(levelData.name);
      setPrice(levelData.price);
    }
  }, [levelData]);

  const handleCourseSelect = (id: string) => {
    setSelectedCourses((prevIds) => {
      if (prevIds.includes(id)) {
        return prevIds.filter((courseId) => courseId !== id);
      } else {
        return [...prevIds, id];
      }
    });
  };

  const filteredCourses = coursesData
    .filter((course) =>
      course.name.toLowerCase().includes(coursesSearchTerm.toLowerCase())
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  const handleEditSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();

    if (levelId !== undefined) {
      formData.append("id", levelId);
    }

    if (tabChange === "general") {
      formData.append("name", name);
      if (price !== null) {
        if (price <= 0) {
          toast.error("Price must be a positive number!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          });
          return;
        } else {
          formData.append("price", price.toString());
        }
      }
    } else if (tabChange === "courses") {
      formData.append("courses", JSON.stringify(selectedCourses));
    }

    try {
      let endpoint = "";

      if (tabChange === "general") {
        endpoint = `${process.env.REACT_APP_EDIT_LEVEL_GENERAL}`;
      } else if (tabChange === "courses") {
        endpoint = `${process.env.REACT_APP_EDIT_LEVEL_COURSES}`;
      }

      await api.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setRefreshData((prevRefresh: any) => prevRefresh + 1);

      // window.location.href = `/levels`;
      toast.success("Grade has been edited successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (error instanceof Error) {
        // You can check for specific error properties or just use a default message
        const errorMessage =
          (error as any)?.response?.data?.message ||
          error.message ||
          "An unexpected error occurred";

        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      } else {
        // Fallback for when error is not an instance of Error
        toast.error("An error occurred while editing Grade", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }

      console.log(error);
    }
  };

  return (
    <div>
      <div className="relative">
        <div className="absolute">
          <Link to={"/grades"}>
            <div className="flex flex-row items-center">
              <IoChevronBackSharp title="grade" />
              <p>Go Back</p>
            </div>
          </Link>
        </div>
        <p className="text-[25px] text-center content-center pb-4">
          Edit {tabChange} Section for {levelData?.name} grade
        </p>
        <div className="flex flex-row justify-between gap-4 w-full">
          <div className="flex flex-col gap-3 max-w-[200px] w-full pt-8">
            <button
              onClick={() => handleTabClick("general")}
              className={`flex flex-row justify-between items-center mr-2 px-2 py-2 border-b text-gray-800 border-gray-500 ${
                tabChange === "general"
                  ? "border-[#4D30B5] hover:border-[#1b1436] duration-300 font-semibold"
                  : " border-[#1b1436]"
              }`}
            >
              <p>Grade Info</p>
              <IoIosArrowForward />
            </button>
            <button
              onClick={() => handleTabClick("courses")}
              className={`flex flex-row justify-between items-center mr-2 px-2 py-2 border-b text-gray-800 border-gray-500 ${
                tabChange === "courses"
                  ? "border-[#4D30B5] hover:border-[#1b1436] duration-300 font-semibold"
                  : " border-[#1b1436]"
              }`}
            >
              <p>Courses</p>
              <IoIosArrowForward />
            </button>
          </div>

          {loading1 === true ? (
            <div className="w-full flex justify-center items-center pt-16">
              <ImSpinner2 size={25} className="animate-spin" />
            </div>
          ) : (
            <>
              {tabChange === "general" ? (
                <form className="w-full" onSubmit={handleEditSubmit}>
                  <div className="flex flex-col gap-4">
                    <div className="grid grid-cols-1 gap-2">
                      <div className="grid grid-cols-2 gap-5">
                        <div className="flex flex-col">
                          <label className="pb-2 text-[20px]">
                            Grade Name:
                          </label>
                          <input
                            required
                            type="text"
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                            className="border-[2px] text-black outline-none p-1 rounded-lg"
                          />
                        </div>
                        <div className="flex flex-col">
                          <label className="pb-2 text-[20px]">Price:</label>
                          <input
                            required
                            type="number"
                            value={price ?? ""}
                            onChange={(e) =>
                              setPrice(
                                e.target.value
                                  ? parseFloat(e.target.value)
                                  : null
                              )
                            }
                            className="border-[2px] text-black outline-none p-1 rounded-lg "
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 flex justify-center">
                      <button
                        type="submit"
                        className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                      >
                        Apply Changes
                      </button>
                    </div>
                  </div>
                </form>
              ) : null}

              {tabChange === "courses" ? (
                <form
                  onSubmit={handleEditSubmit}
                  className="relative h-screen w-full"
                  style={{ maxHeight: "calc(100vh - 170px)" }}
                >
                  <div
                    className="p-4 flex flex-col gap-4 overflow-scroll h-screen "
                    style={{ maxHeight: "calc(100vh - 210px)" }}
                  >
                    <div className="flex flex-col">
                      <label className="pb-2 text-[20px]">Courses:</label>
                      <input
                        type="text"
                        placeholder="Search by name"
                        value={coursesSearchTerm}
                        onChange={(e) => setCoursesSearchTerm(e.target.value)}
                        className="border-[2px] text-black outline-none p-1 rounded-lg"
                      />
                      <div className="rounded-lg w-full mt-2">
                        {filteredCourses && filteredCourses.length > 0 && (
                          <table className="w-full">
                            <thead className="bg-black text-white">
                              <tr>
                                <th className="w-1/5 text-left p-2">ID</th>
                                <th className="w-3/5 text-left p-2">Name</th>
                                <th className="w-1/5 text-left p-2">Action</th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-black border-black border-b-4 ">
                              {filteredCourses.map(
                                (course, index) =>
                                  selectedCourses.includes(course.id) && (
                                    <tr key={course.id}>
                                      <td className="p-2">{course.id}</td>
                                      <td className="p-2">{course.name}</td>
                                      <td className="p-2">
                                        <button
                                          onClick={(e: any) => {
                                            e.preventDefault();
                                            handleCourseSelect(course.id);
                                          }}
                                          className=" bg-red-600 hover:bg-red-800 text-white p-1 rounded duration-300"
                                        >
                                          <RiSubtractFill />
                                        </button>
                                      </td>
                                    </tr>
                                  )
                              )}
                            </tbody>
                            <tbody className="divide-y divide-black ">
                              {filteredCourses.map(
                                (course, index) =>
                                  !selectedCourses.includes(course.id) && (
                                    <tr key={course.id}>
                                      <td className="p-2">{course.id}</td>
                                      <td className="p-2">{course.name}</td>
                                      <td className="p-2">
                                        <button
                                          onClick={(e: any) => {
                                            e.preventDefault();
                                            handleCourseSelect(course.id);
                                          }}
                                          className=" bg-green-600 hover:bg-green-800 text-white p-1 rounded duration-300"
                                        >
                                          <IoMdAdd />
                                        </button>
                                      </td>
                                    </tr>
                                  )
                              )}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </div>

                    <div className=" flex justify-center absolute w-full bottom-0 ">
                      <button
                        type="submit"
                        className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
export default EditLevel;
