import React from "react";

interface DashboardCardProps {
  title: string;
  value: string | number;
  cost?: string;
  icon: React.ReactNode;
}

const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  value,
  cost,
  icon,
}) => {
  return (
    <div
      className={`bg-white p-6 rounded-xl shadow-lg transition-all duration-300 hover:shadow-xl ${
        title === "Total Users" ? "cursor-pointer" : ""
      }`}
    >
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center">
          <div className="p-2 bg-blue-50 rounded-lg">{icon}</div>
          <h2 className="ml-3 text-gray-700 font-medium text-sm">{title}</h2>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div>
          <h3 className="text-3xl font-bold text-gray-800">{value}</h3>
          {cost ? (
            <p className="text-sm text-gray-500 mt-1">${cost}</p>
          ) : (
            <p className="text-sm text-gray-500 mt-1">Registered Users</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
