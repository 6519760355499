import { useState, useEffect } from "react";
import { ImSpinner } from "react-icons/im";
import { fetchData } from "../../../../GlobalFunctions";
import { Link } from "react-router-dom";

function FeedbackCourses() {
  interface CourseData {
    id: string;
    name: string;
    grade_name: string;
  }

  const [courseData, setCourseData] = useState<CourseData[] | null>(null);
  const user_id = localStorage.getItem("user_id") || "default_user";
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_COURSES_FILTER}`,
      setCourseData
    );
    setLoading(false);
  }, [user_id, refresh]);

  if (loading) {
    return (
      <div className="flex justify-center">
        <ImSpinner size={30} className=" animate-spin" />
      </div>
    );
  }

  return (
    <div>
      <div className=" overflow-x-auto shadow-md sm:rounded-lg mb-24">
        <table className="w-full text-sm text-left ">
          <thead className="border-b bg-gray-300">
            <tr>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {courseData?.map((course, index) => (
              <tr key={index} className="bg-gray-200 border-b ">
                <td className="px-6 py-4">
                  {course.name.toLowerCase().includes("ai")
                    ? `AI & Robotics ${course.grade_name
                        .toLowerCase()
                        .replace("grade", "")} - Part I`
                    : `AI & Robotics ${course.grade_name
                        .toLowerCase()
                        .replace("grade", "")} - Part II`}
                </td>
                <td className="px-6 py-4 flex flex-row gap-2">
                  <Link
                    state={{
                      course_id: course.id,
                      grade_name: course.grade_name,
                    }}
                    to={`/feedback-courses/view-course/${course.name}`}
                    className=" bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                  >
                    View Sessions
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default FeedbackCourses;
