import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  // DialogActions,
} from "@mui/material";
// import * as XLSX from "xlsx";

const UserConsumptionDetails = ({
  usersConsumptionDetails,
  setUserDetailsOpen,
}: any) => {
  const [searchQuery, setSearchQuery] = useState("");

  const filteredDetails = usersConsumptionDetails.filter((detail: any) =>
    detail.user_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // const exportToExcel = () => {
  //   const ws = XLSX.utils.json_to_sheet(filteredDetails);
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, "User Consumption Details");
  //   XLSX.writeFile(wb, "UserConsumptionDetails.xlsx");
  // };

  return (
    <div>
      <div className="mb-4">
        <TextField
          label="Search by User Name"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <TableContainer className="shadow border rounded-md">
        <Table stickyHeader className="min-w-full w-full">
          <TableHead>
            <TableRow className="bg-gray-100">
              <TableCell className="font-bold text-gray-700">
                User Name
              </TableCell>
              <TableCell className="font-bold text-gray-700">
                Number of All Questions
              </TableCell>
              <TableCell className="font-bold text-gray-700">
                Number of Normal Questions
              </TableCell>
              <TableCell className="font-bold text-gray-700">
                Number of STT Questions
              </TableCell>
              <TableCell className="font-bold text-gray-700">
                Number of TTS Questions
              </TableCell>
              <TableCell className="font-bold text-gray-700">
                Input Token Cost
              </TableCell>
              <TableCell className="font-bold text-gray-700">
                Output Token Cost
              </TableCell>
              <TableCell className="font-bold text-gray-700">
                STT Cost
              </TableCell>
              <TableCell className="font-bold text-gray-700">
                Total Cost
              </TableCell>
              <TableCell className="font-bold text-gray-700">
                Total Input Tokens
              </TableCell>
              <TableCell className="font-bold text-gray-700">
                Total Output Tokens
              </TableCell>
              <TableCell className="font-bold text-gray-700">
                Total TTS Characters
              </TableCell>
              <TableCell className="font-bold text-gray-700">
                TTS Cost
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredDetails.map((detail: any, index: number) => (
              <TableRow
                key={index}
                className="hover:bg-gray-50 transition-colors"
              >
                <TableCell>{detail.user_name}</TableCell>
                <TableCell>{detail.Number_of_all_questions}</TableCell>
                <TableCell>{detail.Number_of_normal_questions}</TableCell>
                <TableCell>{detail.Number_of_stt_questions}</TableCell>
                <TableCell>{detail.Number_of_tts_questions}</TableCell>
                <TableCell>${detail.input_token_cost.toFixed(4)}</TableCell>
                <TableCell>${detail.output_token_cost.toFixed(4)}</TableCell>
                <TableCell>${detail.stt_cost.toFixed(4)}</TableCell>
                <TableCell>${detail.total_cost.toFixed(4)}</TableCell>
                <TableCell>{detail.total_input_tokens}</TableCell>
                <TableCell>{detail.total_output_tokens}</TableCell>
                <TableCell>{detail.total_tts_characters}</TableCell>
                <TableCell>${detail.tts_cost.toFixed(4)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className=" flex flex-row justify-between w-full">
        {/* <Button variant="contained" color="primary" onClick={exportToExcel}>
          Export to Excel
        </Button> */}
        <Button onClick={() => setUserDetailsOpen(false)} color="primary">
          Close
        </Button>
      </div>
    </div>
  );
};

export default UserConsumptionDetails;
