import { useState, useEffect } from "react";
import { ImSpinner } from "react-icons/im";
import DeleteCourse from "../Admin/AdminCourses/DeleteCourse";
import { fetchData } from "../../../GlobalFunctions";
import { Link } from "react-router-dom";
import "./description.css";
import { FaUser } from "react-icons/fa";
import { BiSolidSchool } from "react-icons/bi";

let currentIdDatabase = 0;

function Courses({ userData }: any) {
  interface CourseData {
    id: string;
    name: string;
    difficulty: string;
    level_name: string;
    description: string;
    image: string;
    number_of_students_have_this_level: string;
    schools: string[];
    grade_name: string;
    level_id: string;
  }

  const [courseData, setCourseData] = useState<CourseData[] | null>(null);
  const user_id = localStorage.getItem("user_id") || "default_user";
  const [deleteCourseOpen, setDeleteCourseOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [filteredCourses, setFilteredCourses] = useState<CourseData[]>([]);
  const [coursesSearchTerm, setCoursesSearchTerm] = useState("");
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);

  const [selectedDifficulty, setSelectedDifficulty] = useState("all");

  const openDeleteCourse = (id: number) => {
    currentIdDatabase = id;
    setDeleteCourseOpen(true);
  };

  const closeDeleteCourse = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setDeleteCourseOpen(false);
    }
  };

  const closeDeleteCourseX = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setCourseData(null);
      setDeleteCourseOpen(false);
    }
  };

  useEffect(() => {
    setLoading1(true);
    setDeleteCourseOpen(false);
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_ALL_COURSES}`,
      (data: any) => {
        setCourseData(data);
        setLoading1(false);
        setLoading2(data.length === 0);
      }
    );
  }, [user_id, refresh]);

  useEffect(() => {
    if (userData.roles.includes("superadmin")) {
      if (courseData) {
        if (selectedDifficulty === "all") {
          const filtered = courseData
            .filter((course) =>
              course.name
                .toLowerCase()
                .includes(coursesSearchTerm.toLowerCase())
            )
            .sort((a, b) => a.name.localeCompare(b.name));
          setFilteredCourses(filtered);
        } else {
          const filtered = courseData
            .filter(
              (course) =>
                course.name
                  .toLowerCase()
                  .includes(coursesSearchTerm.toLowerCase()) &&
                (selectedDifficulty
                  ? course.difficulty === selectedDifficulty
                  : true)
            )
            .sort((a, b) => a.name.localeCompare(b.name));

          setFilteredCourses(filtered);
        }
      }
    } else {
      if (courseData) {
        if (selectedDifficulty === "all") {
          const filtered = courseData
            .filter((course) =>
              course.name
                .toLowerCase()
                .includes(coursesSearchTerm.toLowerCase())
            )
            .sort((a, b) => a.grade_name.localeCompare(b.grade_name));
          setFilteredCourses(filtered);
        } else {
          const filtered = courseData
            .filter(
              (course) =>
                course.name
                  .toLowerCase()
                  .includes(coursesSearchTerm.toLowerCase()) &&
                (selectedDifficulty
                  ? course.difficulty === selectedDifficulty
                  : true)
            )
            .sort((a, b) => a.grade_name.localeCompare(b.grade_name));

          setFilteredCourses(filtered);
        }
      }
    }
  }, [courseData, coursesSearchTerm, selectedDifficulty]);

  if (loading1) {
    return (
      <div className="flex justify-center">
        <ImSpinner size={30} className=" animate-spin" />
      </div>
    );
  }

  if (loading2) {
    return (
      <div className="flex justify-center">
        <p>There are no available courses.</p>
      </div>
    );
  }

  return (
    <div>
      {userData?.roles?.includes("superadmin") && (
        <div className="flex flex-row gap-3 justify-end mb-3">
          <Link
            to={"/courses/add-course"}
            className="bg-[#4D30B5] hover:bg-[#1b1436] text-white py-2 px-4 rounded duration-300 "
          >
            Create Course
          </Link>
        </div>
      )}
      <div>
        <div className="grid grid-cols-3 gap-5 mb-5">
          {/* <div className=" flex flex-col  col-span-2 ">
            <label className="pb-2 text-[20px]">Course Name:</label>
            <input
              type="text"
              placeholder="Search by name"
              value={coursesSearchTerm}
              onChange={(e) => setCoursesSearchTerm(e.target.value)}
              className="border-[2px] text-black outline-none p-1 rounded-lg h-full"
            />
          </div> */}
          {/* <div className=" flex flex-col  ">
            <label className="pb-2 text-[20px]">Difficulty:</label>
            <Select
              value={selectedDifficulty}
              onChange={(e) => setSelectedDifficulty(e.target.value)}
              className=" "
            >
              <MenuItem id="tone-div" key="all" value="all">
                All
              </MenuItem>
              <MenuItem id="tone-div" key="1" value="1">
                Beginner
              </MenuItem>
              <MenuItem id="tone-div" key="2" value="2">
                Intermediate
              </MenuItem>
              <MenuItem id="tone-div" key="3" value="3">
                Advanced
              </MenuItem>
            </Select>
          </div> */}
        </div>
      </div>
      <div className=" mb-16">
        {filteredCourses.length > 0 ? (
          <div className=" grid grid-cols-1">
            {filteredCourses?.map((course, index) => (
              <div
                key={index}
                className={`relative  lg:grid lg:grid-cols-12 mb-10 rounded-lg duration-300 bg-[#EDEAF8] `}
              >
                <div className="col-span-4 flex flex-row gap-4 justify-center items-center bg-gray-200 lg:max-h-[500px] object-cover">
                  <img
                    src={`data:image/jpg;base64,${course.image}`}
                    alt=""
                    loading="lazy"
                    className=" lg:h-[400px] w-full"
                  />
                </div>
                <div className=" col-span-8  p-8 flex flex-col justify-between max-h-[500px]">
                  {userData?.roles?.includes("student") && (
                    <div className="-mb-2 flex flex-row justify-between text-[#4D30B5]">
                      <div className="text-[18px] font-bold ">
                        {course.level_name}
                      </div>
                    </div>
                  )}
                  <p className=" text-[30px] font-bold ">
                    {userData.roles.includes("teacher")
                      ? course.name.toLowerCase().includes("ai")
                        ? `AI & Robotics ${course.grade_name
                            .toLowerCase()
                            .replace("grade", "")} - Part I`
                        : `AI & Robotics ${course.grade_name
                            .toLowerCase()
                            .replace("grade", "")} - Part II`
                      : null}
                    {userData.roles.includes("schooladmin")
                      ? course.name.toLowerCase().includes("ai")
                        ? `AI & Robotics ${course.grade_name
                            .toLowerCase()
                            .replace("grade", "")} - Part I`
                        : `AI & Robotics ${course.grade_name
                            .toLowerCase()
                            .replace("grade", "")} - Part II`
                      : null}
                    {userData.roles.includes("student")
                      ? course.name.toLowerCase().includes("ai")
                        ? `AI & Robotics ${course.level_name
                            .toLowerCase()
                            .replace("grade", "")} - Part I`
                        : `AI & Robotics ${course.level_name
                            .toLowerCase()
                            .replace("grade", "")} - Part II`
                      : null}
                    {userData.roles.includes("superadmin") && course.name}
                  </p>
                  <div className=" text-gray-600 max-h-[200px] overflow-y-scroll">
                    <div
                      className="course-description"
                      dangerouslySetInnerHTML={{
                        __html: course.description as string,
                      }}
                    />
                  </div>
                  <div className="mt-2 text-[18px] t flex flex-col gap-2">
                    <div className="flex flex-row content-center gap-2 mt-5">
                      {course.difficulty === "1" && (
                        <div className="text-[#4D30B5] flex flex-row gap-2">
                          <svg
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                          >
                            <g
                              clipPath="url(#icon-level-introductory_svg__a)"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                d="M21 23V5h4v18h-4zM12 23V11h4v12h-4z"
                                strokeOpacity="0.5"
                              ></path>
                              <path d="M3 23v-8h4v8H3z"></path>
                            </g>
                            <defs>
                              <clipPath id="icon-level-introductory_svg__a">
                                <path
                                  fill="currentColor"
                                  d="M0 0h28v28H0z"
                                ></path>
                              </clipPath>
                            </defs>
                          </svg>
                          <div className="text-[#4D30B5] text-[18px] capitalize">
                            Beginner
                          </div>
                        </div>
                      )}
                      {course.difficulty === "2" && (
                        <div className="text-[#4D30B5] flex flex-row gap-2">
                          <svg
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                          >
                            <g
                              clipPath="url(#icon-level-intermediate_svg__a)"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                d="M21 23V5h4v18h-4z"
                                strokeOpacity="0.5"
                              ></path>
                              <path d="M12 23V11h4v12h-4zM3 23v-8h4v8H3z"></path>
                            </g>
                            <defs>
                              <clipPath id="icon-level-intermediate_svg__a">
                                <path
                                  fill="currentColor"
                                  d="M0 0h28v28H0z"
                                ></path>
                              </clipPath>
                            </defs>
                          </svg>
                          <div className="text-[#4D30B5] text-[18px] capitalize">
                            Intermediate
                          </div>
                        </div>
                      )}
                      {course.difficulty === "3" && (
                        <div className="text-[#4D30B5] flex flex-row gap-2">
                          <svg
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                          >
                            &gt;
                            <g
                              clipPath="url(#icon-level-advanced_svg__a)"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M21 23V5h4v18h-4zM12 23V11h4v12h-4zM3 23v-8h4v8H3z"></path>
                            </g>
                            <defs>
                              <clipPath id="icon-level-advanced_svg__a">
                                <path
                                  fill="currentColor"
                                  d="M0 0h28v28H0z"
                                ></path>
                              </clipPath>
                            </defs>
                          </svg>
                          <div className="text-[#4D30B5] text-[18px] capitalize">
                            Advanced
                          </div>
                        </div>
                      )}
                    </div>

                    <div className=" flex flex-row gap-2 items-center">
                      <FaUser title="Schools" size={22} />
                      <div>
                        Number of students taking this course:{" "}
                        <span className=" font-bold">
                          {course.number_of_students_have_this_level}
                        </span>
                      </div>
                    </div>
                    {userData?.roles?.includes("superadmin") && (
                      <div className=" flex flex-row gap-2 items-center">
                        <BiSolidSchool title="Schools" size={22} />
                        <div>
                          Number of schools taking this course:{" "}
                          <span className=" font-bold">
                            {course.schools.length}
                          </span>
                        </div>
                      </div>
                    )}
                    {userData?.roles?.includes("teacher") && (
                      <div className=" flex flex-row gap-2 items-center">
                        <BiSolidSchool title="Schools" size={22} />
                        <div>
                          Grade:{" "}
                          <span className=" font-bold">
                            {course.grade_name}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="mt-8 gap-2 flex">
                    <Link
                      state={{
                        course_id: course.id,
                        course_grade: course.level_id,
                      }}
                      to={`/courses/view-course/${course.name}`}
                      className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                    >
                      View Course
                    </Link>
                    {userData?.roles?.includes("superadmin") && (
                      <>
                        <Link
                          state={{ course_id: course.id }}
                          to={`/courses/edit-course/${course.name}`}
                          className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                        >
                          Edit Course
                        </Link>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            openDeleteCourse(index);
                          }}
                          className=" bg-red-600 hover:bg-red-800 text-white px-5 py-3 rounded duration-300"
                        >
                          Delete Course
                        </button>
                      </>
                    )}
                    {/* {userData?.roles?.includes("teacher") && (
                      <Link
                        state={{ course_id: course.id }}
                        to={`/courses/manage-course/${course.name}`}
                        className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                      >
                        Manage Course
                      </Link>
                    )} */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex justify-center">
            <ImSpinner size={30} className=" animate-spin" />
          </div>
        )}
      </div>

      {courseData
        ? deleteCourseOpen &&
          userData?.roles?.includes("superadmin") && (
            <DeleteCourse
              closeDeleteCourseX={closeDeleteCourseX}
              idEdit={filteredCourses[currentIdDatabase]?.id}
              nameEdit={filteredCourses[currentIdDatabase].name}
              setRefresh={setRefresh}
              refresh={refresh}
              closeDeleteCourse={closeDeleteCourse}
            />
          )
        : null}
    </div>
  );
}

export default Courses;
