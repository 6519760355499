import { useEffect, useState } from "react";
import { fetchData } from "../../../../GlobalFunctions";
import { Link } from "react-router-dom";

function TeacherGrades({ userData }: any) {
  interface GradesData {
    level_id: string;
    level_name: string;
  }

  const [gradesData, setGradesData] = useState<GradesData[]>([]);

  const [filteredGrades, setFilteredGrades] = useState<GradesData[]>([]);
  const [gradesSearchTerm, setGradesSearchTerm] = useState("");

  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ALL_TEACHER_GRADES}${userData.user_id}`,
      setGradesData
    );
  }, [refresh]);

  useEffect(() => {
    if (gradesData) {
      const filtered = gradesData
        .filter((grade) =>
          grade.level_name
            .toLowerCase()
            .includes(gradesSearchTerm.toLowerCase())
        )
        .sort((a, b) => a.level_name.localeCompare(b.level_name));
      setFilteredGrades(filtered);
    }
  }, [gradesData, gradesSearchTerm, refresh]);

  return (
    <div>
      <div>
        <div className="grid grid-cols-3 gap-5 mb-5">
          <div className=" flex flex-col  ">
            <label className="pb-2 text-[20px]">Grades:</label>
            <input
              type="text"
              placeholder="Search by name"
              value={gradesSearchTerm}
              onChange={(e) => setGradesSearchTerm(e.target.value)}
              className="border-[2px] text-black outline-none p-1 rounded-lg h-full"
            />
          </div>
        </div>
      </div>
      <div className=" overflow-x-auto shadow-md sm:rounded-lg  mb-24">
        <table className="w-full text-sm text-left ">
          <thead className="border-b bg-gray-300">
            <tr>
              <th scope="col" className="px-6 py-3">
                ID
              </th>
              <th scope="col" className="px-6 py-3">
                Name
              </th>

              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredGrades?.map((grade, index) => (
              <tr key={index} className="bg-gray-200 border-b ">
                <td className="px-6 py-4">{index + 1}</td>
                <td className="px-6 py-4">{grade.level_name}</td>

                <td className="px-6 py-4 flex flex-row gap-2">
                  <Link
                    state={{ grade_id: grade.level_id }}
                    to={`/teacher-grades/view-grade/${grade.level_name}`}
                    className=" bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                  >
                    View Grade
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TeacherGrades;
