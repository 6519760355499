import axios, { AxiosError } from "axios";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import api from "../../../../api";
import { Link, useLocation, useParams } from "react-router-dom";
import { IoChevronBackSharp } from "react-icons/io5";
import { MenuItem, Select } from "@mui/material";
import JoditEditor from "jodit-react";
import config from "../Config";
import { MdDelete } from "react-icons/md";

function AddChapter({ setRefresh }: any) {
  interface Quiz {
    question: string;
    options: string[];
    correctAnswer: string;
  }

  interface VideoExplainer {
    name: string;
    link: string;
    transcript: string;
  }

  interface LiveVideo {
    name: string;
    link: string;
    transcript: string;
  }

  const [name, setName] = useState("");
  const [description, setDescription] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [order, setOrder] = useState("1");
  const [imageSrc, setImageSrc] = useState<string>("");
  const [simulation, setSimulation] = useState<string>("");
  const [guide, setGuide] = useState<string>("");
  const [teacherGuide, setTeacherGuide] = useState<string>("");
  const [studentGuide, setStudentGuide] = useState<string>("");
  const [story, setStory] = useState<string>("");
  const [storyVideo, setStoryVideo] = useState<string>("");
  const [handsOnActivity, setHandsOnActivity] = useState<string>("");

  const editor = useRef(null);

  const defaultVideoExplainer: VideoExplainer = {
    name: "",
    link: "",
    transcript: "",
  };
  const [videoExplainer, setVideoExplainer] = useState<VideoExplainer[]>([
    defaultVideoExplainer,
  ]);

  const defaultLiveVideo: LiveVideo = {
    name: "",
    link: "",
    transcript: "",
  };
  const [liveVideo, setLiveVideo] = useState<VideoExplainer[]>([
    defaultLiveVideo,
  ]);

  const [published, setPublished] = useState<string>("false");

  const defaultQuiz: Quiz = {
    question: "",
    options: ["", ""],
    correctAnswer: "default",
  };
  const [quiz, setQuiz] = useState<Quiz[]>([defaultQuiz]);

  const { course_name } = useParams();
  const { state } = useLocation();
  const [courseId, setCourseId] = useState<string>(() => {
    const storedCourseId = window.localStorage.getItem("courseId") || "";
    return (state && state.course_id) || storedCourseId;
  });

  const addExplainerVideo = () => {
    setVideoExplainer([
      ...videoExplainer,
      {
        name: "",
        link: "",
        transcript: "",
      },
    ]);
  };

  //Checkbox Included
  const [videoExplainerIncluded, setVideoExplainerIncluded] = useState(false);
  const [liveVideoIncluded, setLiveVideoIncluded] = useState(false);
  const [lessonContentIncluded, setLessonContentIncluded] = useState(false);
  const [storyIncluded, setStoryIncluded] = useState(false);
  const [simulationIncluded, setSimulationIncluded] = useState(false);
  const [teacherGuideIncluded, setTeacherGuideIncluded] = useState(false);
  const [chatIncluded, setChatIncluded] = useState(false);
  const [quizIncluded, setQuizIncluded] = useState(false);
  const [handsOnActivityIncluded, setHandsOnActivityIncluded] = useState(false);

  const removeExplainerVideo = (index: number) => {
    if (videoExplainer.length === 1) {
      return toast.error("cant have less 0 videos with checkbox enabled!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } else {
      const newVideos = videoExplainer.filter((_, vIndex) => vIndex !== index);
      setVideoExplainer(newVideos);
    }
  };

  const handleExplainerNameChange = (index: number, value: string) => {
    const newVideo = [...videoExplainer];
    newVideo[index].name = value;
    setVideoExplainer(newVideo);
  };

  const handleExplainerLinkChange = (index: number, value: string) => {
    const newVideo = [...videoExplainer];
    newVideo[index].link = value;
    setVideoExplainer(newVideo);
  };

  const handleExplainerTranscriptChange = (index: number, value: string) => {
    const newVideo = [...videoExplainer];
    newVideo[index].transcript = value;
    setVideoExplainer(newVideo);
  };

  const addLiveVideo = () => {
    setLiveVideo([
      ...liveVideo,
      {
        name: "",
        link: "",
        transcript: "",
      },
    ]);
  };

  const removeLiveVideo = (index: number) => {
    if (liveVideo.length === 1) {
      return toast.error("cant have less 0 videos with checkbox enabled!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } else {
      const newVideos = liveVideo.filter((_, vIndex) => vIndex !== index);
      setLiveVideo(newVideos);
    }
  };

  const handleLiveNameChange = (index: number, value: string) => {
    const newVideo = [...liveVideo];
    newVideo[index].name = value;
    setLiveVideo(newVideo);
  };

  const handleLiveLinkChange = (index: number, value: string) => {
    const newVideo = [...liveVideo];
    newVideo[index].link = value;
    setLiveVideo(newVideo);
  };

  const handleLiveTranscriptChange = (index: number, value: string) => {
    const newVideo = [...liveVideo];
    newVideo[index].transcript = value;
    setLiveVideo(newVideo);
  };

  const addQuestion = () => {
    setQuiz([
      ...quiz,
      {
        question: "",
        options: ["", ""],
        correctAnswer: "default",
      },
    ]);
  };

  const removeQuestion = (index: number) => {
    const newQuestions = quiz.filter((_, qIndex) => qIndex !== index);
    setQuiz(newQuestions);
  };

  const addOption = (qIndex: number) => {
    const newQuiz = [...quiz];
    newQuiz[qIndex].options.push("");
    setQuiz(newQuiz);
  };

  const removeOption = (qIndex: number, oIndex: number) => {
    const newQuiz = [...quiz];
    if (newQuiz[qIndex].options.length > 2) {
      newQuiz[qIndex].options.splice(oIndex, 1);
      setQuiz(newQuiz);
    } else {
      toast.error("A question must have at least two option", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    }
  };

  const handleQuestionChange = (index: number, value: string) => {
    const newQuiz = [...quiz];
    newQuiz[index].question = value;
    setQuiz(newQuiz);
  };

  const handleOptionChange = (
    qIndex: number,
    oIndex: number,
    value: string
  ) => {
    const newQuiz = [...quiz];
    newQuiz[qIndex].options[oIndex] = value;
    newQuiz[qIndex].correctAnswer = "default";
    setQuiz(newQuiz);
  };

  const handleCorrectAnswerChange = (index: number, value: string) => {
    const newQuiz = [...quiz];
    newQuiz[index].correctAnswer = value;
    setQuiz(newQuiz);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result as string);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleUploadSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!file) {
      toast.error("Please select an image!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      return;
    }

    try {
      const formData = new FormData();
      formData.append("title", name);
      formData.append("description", description);
      formData.append("video_order", order);
      formData.append("explainer_video", JSON.stringify(videoExplainer));
      formData.append("live_video", JSON.stringify(liveVideo));
      formData.append("student_guide", studentGuide);
      formData.append("story", story);
      formData.append("story_video", storyVideo);
      formData.append("logo", file);
      formData.append("simulation", simulation);
      formData.append("guide", guide);
      formData.append("teacher_guide", teacherGuide);
      formData.append("hands_on_activity", handsOnActivity);
      formData.append("published", published);
      formData.append("include_chat", String(chatIncluded));
      formData.append("include_quiz", String(quizIncluded));
      formData.append("include_simulation", String(simulationIncluded));
      formData.append(
        "include_video_explainer",
        String(videoExplainerIncluded)
      );
      formData.append("include_live_video", String(liveVideoIncluded));
      formData.append("include_lesson_content", String(lessonContentIncluded));
      formData.append("include_story", String(storyIncluded));
      formData.append("include_teacher_guide", String(teacherGuideIncluded));
      formData.append(
        "include_hands_on_activity",
        String(handsOnActivityIncluded)
      );

      if (quizIncluded) {
        formData.append("quiz", JSON.stringify(quiz));
      }

      await api.post(
        `${process.env.REACT_APP_CREATE_CHAPTER}${courseId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setRefresh((prevRefresh: any) => prevRefresh + 1);
      window.localStorage.setItem("courseId", courseId);
      window.location.href = `/courses/edit-course/${course_name}`;
      toast.success("Course has been created successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<{ message: string }>;
        const errorMessage =
          axiosError.response?.data?.message || axiosError.message;

        toast.error(
          `An error occurred while creating the Course: ${errorMessage}`,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          }
        );
      } else {
        toast.error("An unexpected error occurred while creating the Course", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }
      console.error("Error creating the Course:", error);
    }
  };

  return (
    <section
      className="overflow-hidden h-screen "
      style={{ maxHeight: "calc(100vh - 50px)" }}
    >
      <div className="flex flex-row items-center w-[210px]  gap-1">
        <Link
          state={{ course_id: courseId }}
          className="px-2 hover:-ml-1 hover:pr-3 duration-200 rosunded-lg "
          to={`/courses/edit-course/${course_name}`}
        >
          <IoChevronBackSharp title="Profile" size={17} />
        </Link>
        <p className="font-bold text-[20px]">Add Lesson:</p>
      </div>

      <form
        onSubmit={handleUploadSubmit}
        className="relative h-screen"
        style={{ maxHeight: "calc(100vh - 170px)" }}
      >
        <div
          className="p-4 flex flex-col gap-4 overflow-scroll h-screen "
          style={{ maxHeight: "calc(100vh - 210px)" }}
        >
          <div className=" flex flex-col ">
            <label className="pb-2 text-[22px] font-semibold">
              Session Title:
            </label>
            <input
              required
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className=" border-[2px] text-black  outline-none p-1 rounded-lg"
            />
          </div>

          <div className=" flex flex-col mt-10">
            <label className="pb-2 text-[22px] font-semibold">
              Description:
            </label>
            <JoditEditor
              ref={editor}
              config={config}
              value={description}
              onChange={setDescription}
            />
          </div>

          <div className=" flex flex-col mt-10">
            <label className="pb-2 text-[22px] font-semibold">Image:</label>
            <div className="col-span-4  gap-4 justify-center items-center mb-2 ">
              <img width={200} src={imageSrc} alt="" loading="lazy" />
            </div>
            <input
              required
              type="file"
              accept=".png, .jpg, .jpeg, .gif"
              onChange={handleFileChange}
            />
          </div>

          <div className="flex flex-row mt-10">
            <label
              className="text-[22px] font-semibold content-center mr-4"
              htmlFor="english"
            >
              Published:
            </label>
            <div className=" w-96">
              <Select
                value={published}
                onChange={(e) => setPublished(e.target.value)}
              >
                <MenuItem key="no" value="false">
                  No
                </MenuItem>
                <MenuItem key="yes" value="true">
                  Yes
                </MenuItem>
              </Select>
            </div>
          </div>

          <div className="flex flex-row mt-10">
            <label
              className="text-[22px] font-semibold content-center mr-4"
              htmlFor="english"
            >
              Order:
            </label>
            <div className="w-96">
              <Select value={order} onChange={(e) => setOrder(e.target.value)}>
                <MenuItem key="beginner" value="1">
                  Video Explainer First
                </MenuItem>
                <MenuItem key="intermediate" value="2">
                  Real Video First
                </MenuItem>
              </Select>
            </div>
          </div>

          <div className="mt-10">
            <div className="text-[22px] font-semibold content-center mb-2">
              Include Components:
            </div>
            <div className="grid grid-cols-4 items-center border border-black p-2 rounded-lg">
              <div className=" flex items-center gap-2 p-2">
                <input
                  type="checkbox"
                  id="videoexplainer"
                  className="h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                  checked={videoExplainerIncluded}
                  onChange={() =>
                    setVideoExplainerIncluded(!videoExplainerIncluded)
                  }
                />
                <label
                  htmlFor="videoexplainer"
                  className="text-gray-700 font-medium"
                >
                  Video Explainer
                </label>
              </div>
              <div className=" flex items-center gap-2 p-2">
                <input
                  type="checkbox"
                  id="livevideo"
                  className="h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                  checked={liveVideoIncluded}
                  onChange={() => setLiveVideoIncluded(!liveVideoIncluded)}
                />
                <label
                  htmlFor="livevideo"
                  className="text-gray-700 font-medium"
                >
                  Live Video
                </label>
              </div>
              <div className=" flex items-center gap-2 p-2">
                <input
                  type="checkbox"
                  id="lessoncontent"
                  className="h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                  checked={lessonContentIncluded}
                  onChange={() =>
                    setLessonContentIncluded(!lessonContentIncluded)
                  }
                />
                <label
                  htmlFor="lessoncontent"
                  className="text-gray-700 font-medium"
                >
                  Session Content
                </label>
              </div>
              <div className=" flex items-center gap-2 p-2">
                <input
                  type="checkbox"
                  id="story"
                  className="h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                  checked={storyIncluded}
                  onChange={() => setStoryIncluded(!storyIncluded)}
                />
                <label htmlFor="story" className="text-gray-700 font-medium">
                  Story
                </label>
              </div>
              <div className=" flex items-center gap-2 p-2">
                <input
                  type="checkbox"
                  id="simulation"
                  className="h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                  checked={simulationIncluded}
                  onChange={() => setSimulationIncluded(!simulationIncluded)}
                />
                <label
                  htmlFor="simulation"
                  className="text-gray-700 font-medium"
                >
                  Simulation
                </label>
              </div>
              <div className=" flex items-center gap-2 p-2">
                <input
                  type="checkbox"
                  id="teacherguide"
                  className="h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                  checked={teacherGuideIncluded}
                  onChange={() =>
                    setTeacherGuideIncluded(!teacherGuideIncluded)
                  }
                />
                <label
                  htmlFor="teacherguide"
                  className="text-gray-700 font-medium"
                >
                  Teacher Guide
                </label>
              </div>
              <div className=" flex items-center gap-2 p-2">
                <input
                  type="checkbox"
                  id="handsonactivity"
                  className="h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                  checked={handsOnActivityIncluded}
                  onChange={() =>
                    setHandsOnActivityIncluded(!handsOnActivityIncluded)
                  }
                />
                <label
                  htmlFor="handsonactivity"
                  className="text-gray-700 font-medium"
                >
                  Hands-on Activity
                </label>
              </div>
              <div className=" flex items-center gap-2 p-2">
                <input
                  type="checkbox"
                  id="quiz"
                  className="h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                  checked={quizIncluded}
                  onChange={() => setQuizIncluded(!quizIncluded)}
                />
                <label htmlFor="quiz" className="text-gray-700 font-medium">
                  Quiz
                </label>
              </div>
              <div className=" flex items-center gap-2 p-2">
                <input
                  type="checkbox"
                  id="chat"
                  className="h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                  checked={chatIncluded}
                  onChange={() => setChatIncluded(!chatIncluded)}
                />
                <label htmlFor="chat" className="text-gray-700 font-medium">
                  Chat
                </label>
              </div>
            </div>
          </div>

          {videoExplainerIncluded && (
            <div className="mt-10">
              <div className="text-[22px] font-semibold content-center mb-2 ">
                Video Explainers:
              </div>
              <div className="mb-5 flex flex-col gap-5">
                {videoExplainer?.map((video, vIndex) => (
                  <div>
                    <div className="fkex flex-row justify-between relative">
                      <div className="pb-2 text-[18px]">
                        Video {vIndex + 1}:
                      </div>
                      <div
                        className="bg-red-600 hover:bg-red-800 text-white p-2 rounded duration-300 absolute right-0 top-0 cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          removeExplainerVideo(vIndex);
                        }}
                      >
                        <MdDelete />
                      </div>
                    </div>
                    <div
                      key={vIndex}
                      style={{}}
                      className="flex flex-col gap-2 border-2 border-[#4D30B5] rounded-lg p-2 relative"
                    >
                      <div className=" flex flex-row gap-2 w-full items-center">
                        <label className="pb-2 text-[18px]">Name:</label>
                        <input
                          required
                          type="text"
                          value={video.name}
                          onChange={(e) => {
                            handleExplainerNameChange(vIndex, e.target.value);
                          }}
                          className=" border-[2px] text-black outline-none p-1 rounded-lg flex-grow"
                        />
                      </div>

                      <div className=" flex flex-row gap-2 w-full items-center">
                        <label className="pb-2 text-[18px]">Link:</label>
                        <input
                          required
                          type="text"
                          value={video.link}
                          onChange={(e) => {
                            handleExplainerLinkChange(vIndex, e.target.value);
                          }}
                          className=" border-[2px] text-black outline-none p-1 rounded-lg flex-grow"
                        />
                      </div>

                      <div className="mt-2 flex flex-row gap-2 w-full items-center">
                        <label className="text-[18px] flex-shrink-0">
                          Transcript:
                        </label>
                        <textarea
                          required
                          value={video.transcript}
                          onChange={(e) =>
                            handleExplainerTranscriptChange(
                              vIndex,
                              e.target.value
                            )
                          }
                          className=" border-[2px] text-black  outline-none p-1 rounded-lg h-40 flex-grow"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <button
                className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                onClick={(e) => {
                  e.preventDefault();
                  addExplainerVideo();
                }}
              >
                Add Video
              </button>
            </div>
          )}

          {liveVideoIncluded && (
            <div className="mt-10">
              <div className="text-[22px] font-semibold content-center mb-2">
                Live Video:
              </div>
              <div className="mb-5 flex flex-col gap-5">
                {liveVideo.map((video, vIndex) => (
                  <div>
                    <div className="fkex flex-row justify-between relative">
                      <div className="pb-2 text-[18px]">
                        Video {vIndex + 1}:
                      </div>
                      <div
                        className="bg-red-600 hover:bg-red-800 text-white p-2 rounded duration-300 absolute right-0 top-0 cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          removeLiveVideo(vIndex);
                        }}
                      >
                        <MdDelete />
                      </div>
                    </div>
                    <div
                      key={vIndex}
                      style={{}}
                      className="flex flex-col gap-2 border-2 border-[#4D30B5] rounded-lg p-2 relative"
                    >
                      <div className=" flex flex-row gap-2 w-full items-center">
                        <label className="pb-2 text-[18px]">Name:</label>
                        <input
                          required
                          type="text"
                          value={video.name}
                          onChange={(e) => {
                            handleLiveNameChange(vIndex, e.target.value);
                          }}
                          className=" border-[2px] text-black outline-none p-1 rounded-lg flex-grow"
                        />
                      </div>

                      <div className=" flex flex-row gap-2 w-full items-center">
                        <label className="pb-2 text-[18px]">Link:</label>
                        <input
                          required
                          type="text"
                          value={video.link}
                          onChange={(e) => {
                            handleLiveLinkChange(vIndex, e.target.value);
                          }}
                          className=" border-[2px] text-black outline-none p-1 rounded-lg flex-grow"
                        />
                      </div>

                      <div className="mt-2 flex flex-row gap-2 w-full items-center">
                        <label className="text-[18px] flex-shrink-0">
                          Transcript:
                        </label>
                        <textarea
                          required
                          value={video.transcript}
                          onChange={(e) =>
                            handleLiveTranscriptChange(vIndex, e.target.value)
                          }
                          className=" border-[2px] text-black  outline-none p-1 rounded-lg h-40 flex-grow"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <button
                className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                onClick={(e) => {
                  e.preventDefault();
                  addLiveVideo();
                }}
              >
                Add Video
              </button>
            </div>
          )}

          {lessonContentIncluded && (
            <div className=" flex flex-col mt-10">
              <label className="pb-2 text-[22px] font-semibold">
                Session Content:
              </label>
              <JoditEditor
                ref={editor}
                config={config}
                value={studentGuide}
                onChange={setStudentGuide}
              />
            </div>
          )}

          {storyIncluded && (
            <div>
              <div className=" flex flex-col mt-10">
                <label className="pb-2 text-[22px] font-semibold">
                  Story Link:
                </label>
                <input
                  type="text"
                  value={storyVideo}
                  onChange={(e) => setStoryVideo(e.target.value)}
                  className=" border-[2px] text-black outline-none p-1 rounded-lg flex-grow"
                />
              </div>
              <div className=" flex flex-col ">
                <label className="pb-2 text-[22px] font-semibold">Story:</label>
                <JoditEditor
                  ref={editor}
                  config={config}
                  value={story}
                  onChange={setStory}
                />
              </div>
            </div>
          )}

          {simulationIncluded && (
            <div className="">
              <div className="text-[22px] font-semibold content-center mb-2">
                Simulation:
              </div>
              <div className="mb-5 flex flex-col gap-5">
                <div className="fkex flex-row justify-between relative"></div>
                <div className="flex flex-col gap-2 border-2 border-[#4D30B5] rounded-lg p-2 relative">
                  <div className=" flex flex-row gap-2 w-full items-center">
                    <label className="pb-2 text-[18px]">Link:</label>
                    <input
                      required
                      type="text"
                      value={simulation}
                      onChange={(e) => setSimulation(e.target.value)}
                      className=" border-[2px] text-black outline-none p-1 rounded-lg flex-grow"
                    />
                  </div>

                  <div className="mt-2 flex flex-row gap-2 w-full items-center">
                    <label className="text-[18px] flex-shrink-0">Guide:</label>
                    <textarea
                      required
                      value={guide}
                      onChange={(e) => setGuide(e.target.value)}
                      className=" border-[2px] text-black  outline-none p-1 rounded-lg h-40 flex-grow"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {teacherGuideIncluded && (
            <div className=" flex flex-col mt-10">
              <label className="pb-2 text-[22px] font-semibold">
                Teacher Guide:
              </label>
              <JoditEditor
                ref={editor}
                config={config}
                value={teacherGuide}
                onChange={setTeacherGuide}
              />
            </div>
          )}

          {handsOnActivityIncluded && (
            <div>
              <div className=" flex flex-col ">
                <label className="pb-2 text-[22px] font-semibold">
                  Activity:
                </label>
                <JoditEditor
                  ref={editor}
                  config={config}
                  value={handsOnActivity}
                  onChange={setHandsOnActivity}
                />
              </div>
            </div>
          )}

          {quizIncluded && (
            <div className="mt-10">
              <div className="text-[22px] font-semibold content-center mb-2">
                Questions:
              </div>
              <div className="mb-5 flex flex-col gap-5">
                {quiz?.map((question, qIndex) => (
                  <div>
                    <div className="fkex flex-row justify-between relative">
                      <div className="pb-2 text-[18px]">MCQ {qIndex + 1}:</div>
                      <div
                        className="bg-red-600 hover:bg-red-800 text-white p-2 rounded duration-300 absolute right-0 top-0 cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          removeQuestion(qIndex);
                        }}
                      >
                        <MdDelete />
                      </div>
                    </div>
                    <div
                      key={qIndex}
                      style={{}}
                      className="flex flex-col gap-2 border-2 border-[#4D30B5] rounded-lg p-2 relative"
                    >
                      <div className=" flex flex-row gap-2 w-full items-center">
                        <label className="pb-2 text-[18px]">Question:</label>
                        <input
                          required
                          type="text"
                          value={question.question}
                          onChange={(e) => {
                            handleQuestionChange(qIndex, e.target.value);
                          }}
                          className=" border-[2px] text-black outline-none p-1 rounded-lg flex-grow"
                        />
                      </div>

                      <div className="flex flex-col gap-2">
                        <label className=" text-[18px]">Options:</label>
                        <div className="flex flex-col gap-2 ml-5">
                          {question.options.map((option, oIndex) => (
                            <div
                              key={oIndex}
                              className=" flex flex-row gap-2 w-full items-center"
                            >
                              <label className="pb-2 text-[18px]">
                                {oIndex + 1}:
                              </label>
                              <input
                                required
                                type="text"
                                value={option}
                                onChange={(e) =>
                                  handleOptionChange(
                                    qIndex,
                                    oIndex,
                                    e.target.value
                                  )
                                }
                                className=" border-[2px] text-black outline-none p-1 rounded-lg w-full"
                              />
                              <div
                                className="bg-red-600 hover:bg-red-800 text-white p-2 rounded duration-300  cursor-pointer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  removeOption(qIndex, oIndex);
                                }}
                              >
                                <MdDelete />
                              </div>
                            </div>
                          ))}
                        </div>
                        <button
                          className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300 w-fit ml-10"
                          onClick={(e) => {
                            e.preventDefault();
                            addOption(qIndex);
                          }}
                        >
                          Add Option
                        </button>
                      </div>
                      <div className="mt-2 flex flex-row gap-2 w-full items-center">
                        <label className="text-[18px] flex-shrink-0">
                          Correct Answer:
                        </label>
                        <div className=" ">
                          <Select
                            value={question.correctAnswer}
                            onChange={(e) =>
                              handleCorrectAnswerChange(qIndex, e.target.value)
                            }
                          >
                            <MenuItem disabled value="default">
                              <em>Select Option</em>
                            </MenuItem>
                            {question.options.map((option, oIndex) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <button
                className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                onClick={(e) => {
                  e.preventDefault();
                  addQuestion();
                }}
              >
                Add MCQ
              </button>
            </div>
          )}
        </div>

        <div className=" flex justify-center absolute w-full bottom-0 ">
          <button
            type="submit"
            className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
          >
            Add Session
          </button>
        </div>
      </form>
    </section>
  );
}

export default AddChapter;
