import { Outlet } from "react-router";
import SideMenu from "../Navigations/SideMenu";
import NavBar from "../Navigations/NavBar";

function Dashboard({
  userData,
  setUserData,
  setUserID,
  changeSideActive,
  sideActive,
  mainSidebarOpen,
  changeMainSidebarOpen,
}: any) {
  return (
    <div>
      <NavBar changeSideActive={changeSideActive} userData={userData} />
      <div
        className="h-screen lg:flex lg:flex-row -mt-[1px]"
        style={{ maxHeight: "calc(100vh - 70px)" }}
      >
        <div
          className={`${
            mainSidebarOpen ? "lg:w-[17%]" : "lg:w-[5%]"
          }  bg-[#20144C] h-full duration-300 ${
            sideActive === false ? "max-lg:hidden" : ""
          }`}
        >
          <SideMenu
            changeSideActive={changeSideActive}
            sideActive={sideActive}
            userData={userData}
            setUserData={setUserData}
            setUserID={setUserID}
            changeMainSidebarOpen={changeMainSidebarOpen}
            mainSidebarOpen={mainSidebarOpen}
          />
        </div>
        <div className=" lg:col-span-6 h-screen flex flex-col w-full bg-[#F8FBFD]">
          <div className=" lg:grid grid-cols-11 h-full overflow-y-scroll custom-scrollbar-chat p-4  bg-[#F8FBFD]">
            <div id="parent" className=" col-span-11">
              <div
                className="flex flex-col h-screen "
                style={{ maxHeight: "calc(100vh - 105px)" }}
              >
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
