import { useEffect, useState } from "react";
import { BiEdit } from "react-icons/bi";
import { Link } from "react-router-dom";
import { fetchData } from "../../../../GlobalFunctions";
import { MenuItem, Select } from "@mui/material";
import Cookies from "js-cookie";

function SALevels({ setRefreshData, userData }: any) {
  interface LevelsData {
    level_id: string;
    level_name: string;
    max_number_of_students: string;
    assigned_seats: string;
    remaining_seats: string;
    total_level_price: string;
    contract_level_id: string;
    school_name: string;
    school_id: string;
    deadline_date: string;
    allow_edit: string;
  }
  interface SchoolsData {
    name: string;
    id: string;
  }

  const user_id = Cookies.get("user_id") || "default_user";

  const [levelsData, setLevelsData] = useState<LevelsData[]>([]);
  const [schoolsData, setSchoolsData] = useState<SchoolsData[]>([]);
  const [filteredLevels, setFilteredLevels] = useState<LevelsData[] | null>(
    null
  );
  const [selectedSchool, setSelectedSchool] = useState("all");

  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ALL_LEVELS_BY_SA_ID}${user_id}`,
      setLevelsData
    );
    if (userData.roles.includes("superadmin")) {
      fetchData(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ALL_SCHOOLS}`,
        setSchoolsData
      );
    }
  }, [refresh, setRefreshData]);

  useEffect(() => {
    if (levelsData) {
      if (selectedSchool === "all") {
        setFilteredLevels(levelsData);
      } else {
        const filtered = levelsData.filter((level) =>
          selectedSchool ? level.school_name === selectedSchool : true
        );
        setFilteredLevels(filtered);
      }
    }
  }, [levelsData, selectedSchool]);

  console.log(levelsData);

  return (
    <div className="w-full">
      {levelsData && levelsData.length > 0 ? (
        <div className="overflow-x-auto shadow-md sm:rounded-lg w-full mb-24">
          {userData?.roles?.includes("superadmin") && (
            <div className="grid grid-cols-3 gap-5 mb-5">
              <div className=" flex flex-col  ">
                <label className="pb-2 text-[20px]">School:</label>
                <Select
                  value={selectedSchool}
                  onChange={(e) => setSelectedSchool(e.target.value)}
                  className=" "
                >
                  <MenuItem id="tone-div" key="all" value="all">
                    All
                  </MenuItem>
                  {schoolsData &&
                    schoolsData.map((school, index) => (
                      <MenuItem id="tone-div" key={index} value={school.name}>
                        {school.name}
                      </MenuItem>
                    ))}
                </Select>
              </div>
            </div>
          )}
          <table className="w-full text-sm text-center ">
            <thead className="border-b bg-gray-300">
              <tr>
                <th scope="col" className="px-6 py-3 w-20">
                  ID
                </th>
                <th scope="col" className="px-6 py-3">
                  Grade
                </th>
                {userData?.roles?.includes("superadmin") && (
                  <th scope="col" className="px-6 py-3">
                    School
                  </th>
                )}
                <th scope="col" className="px-6 py-3">
                  Maximum Number Of Seats
                </th>
                <th scope="col" className="px-6 py-3">
                  Assigned Seats
                </th>
                <th scope="col" className="px-6 py-3">
                  Remaining Seats
                </th>
                <th scope="col" className="px-6 py-3">
                  Edit Due Date
                </th>
                {/* <th scope="col" className="px-6 py-3">
                  Price Per Student
                </th> */}
                <th scope="col" className="px-6 py-3 w-[180px]">
                  Grade Management
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredLevels?.map((level, index) => (
                <tr key={index} className="bg-gray-200 border-b">
                  <td className="px-6 py-4">{level.contract_level_id}</td>
                  <td className="px-6 py-4">{level.level_name}</td>
                  {userData?.roles?.includes("superadmin") && (
                    <td className="px-6 py-4">{level.school_name}</td>
                  )}
                  <td className="px-6 py-4">{level.max_number_of_students}</td>
                  <td className="px-6 py-4">{level.assigned_seats}</td>
                  <td className="px-6 py-4">{level.remaining_seats}</td>
                  <td className="px-6 py-4">{level.deadline_date}</td>
                  {/* <td className="px-6 py-4">{level.total_level_price}$</td> */}
                  {userData?.roles?.includes("superadmin") ? (
                    <td className="px-6 py-4 flex flex-row gap-2 w-40 justify-center">
                      <Link
                        state={{
                          level_id: level.level_id,
                          contract_level_id: level.contract_level_id,
                          school_id: level.school_id,
                          school_name: level.school_name,
                        }}
                        to={`/school_grades/edit_grade/${level.level_name}`}
                        className=" bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                      >
                        <BiEdit />
                      </Link>
                    </td>
                  ) : level.allow_edit === "yes" ? (
                    <td className="px-6 py-4 flex flex-row gap-2 w-40 justify-center">
                      <Link
                        state={{
                          level_id: level.level_id,
                          contract_level_id: level.contract_level_id,
                          school_id: level.school_id,
                          school_name: level.school_name,
                        }}
                        to={`/school_grades/edit_grade/${level.level_name}`}
                        className=" bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                      >
                        <BiEdit />
                      </Link>
                    </td>
                  ) : (
                    <td className="px-6 py-4 flex flex-row gap-2 w-40 justify-center"></td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  );
}

export default SALevels;
