import { useEffect, useState } from "react";
import { BiSolidHide, BiSolidShow } from "react-icons/bi";
import { Link, useLocation, useParams } from "react-router-dom";
import { ImSpinner } from "react-icons/im";
import { IoChevronBackSharp } from "react-icons/io5";
import api from "../../../../api";
import { toast } from "react-toastify";
import axios, { AxiosError } from "axios";

function EditAllowViewCourse({ setRefreshData }: any) {
  interface ChaptersData {
    id: string;
    title: string;
    published: boolean;
  }

  const { course_name } = useParams();
  const { state } = useLocation();
  const [courseId, setCourseId] = useState<string>(() => {
    const storedCourseId = window.localStorage.getItem("courseId") || "";
    return (state && state.course_id) || storedCourseId;
  });
  const [chaptersData, setChaptersData] = useState<ChaptersData[] | null>(null);
  const [filteredChapters, setFilteredChapters] = useState<
    ChaptersData[] | null
  >(null);
  const [chaptersSearchTerm, setChaptersSearchTerm] = useState("");
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    async function fetchData1() {
      try {
        const formData = new FormData();

        const response = await api.post(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_CHAPTERS_BY_COURSE_ID}${courseId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const data = response.data;
        setChaptersData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData1();
  }, [refresh]);

  useEffect(() => {
    if (chaptersData) {
      const filtered = chaptersData
        .filter((chapter) =>
          chapter.title.toLowerCase().includes(chaptersSearchTerm.toLowerCase())
        )
        .sort((a, b) => a.title.localeCompare(b.title));
      setFilteredChapters(filtered);
    }
  }, [chaptersData, chaptersSearchTerm]);

  const handleChangeChapterOpen = async (e: any, chapter_id: string) => {
    e.preventDefault();

    try {
      const formData = new FormData();

      formData.append("chapter_id", chapter_id);

      await api.post(
        `${process.env.REACT_APP_EDIT_PUBLISHED_STATUS}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setRefresh((prevRefresh: any) => prevRefresh + 1);
      window.localStorage.setItem("courseId", courseId);
      toast.success("Chapter status has been updated!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<{ message: string }>;
        const errorMessage =
          axiosError.response?.data?.message || axiosError.message;

        toast.error(
          `An error occurred while updating the chapter status: ${errorMessage}`,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          }
        );
      } else {
        toast.error(
          "An unexpected error occurred while updating the chapter status!",
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          }
        );
      }
      console.error("Error updating the chapter status:", error);
    }
  };

  return (
    <div>
      <div className="relative mb-20">
        <div className="absolute">
          <Link to={"/chapters_published_status"}>
            <div className="flex flex-row items-center">
              <IoChevronBackSharp title="Plan" />
              <p>Go Back</p>
            </div>
          </Link>
        </div>
        <p className="text-[25px] text-center content-center pb-4">
          Edit {course_name} Course
        </p>
        <div>
          <div className="grid grid-cols-3 gap-5 mb-5">
            <div className=" flex flex-col  ">
              <label className="pb-2 text-[20px]">Chapter Name:</label>
              <input
                type="text"
                placeholder="Search by name"
                value={chaptersSearchTerm}
                onChange={(e) => setChaptersSearchTerm(e.target.value)}
                className="border-[2px] text-black outline-none p-1 rounded-lg h-full"
              />
            </div>
          </div>
        </div>
        {filteredChapters && filteredChapters.length > 0 ? (
          <div className=" overflow-x-auto shadow-md sm:rounded-lg mb-20">
            <table className="w-full text-sm text-center">
              <thead className="border-b bg-gray-300">
                <tr>
                  <th scope="col" className="px-6 py-3 ">
                    ID
                  </th>
                  <th scope="col" className="px-6 py-3 w-[60%] text-left">
                    Chapter Name
                  </th>
                  <th scope="col" className="px-6 py-3 ">
                    Chapter Published Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredChapters?.map((chapter, index) => (
                  <tr key={index} className="bg-gray-200 border-b ">
                    <td className="px-6 py-4">{chapter.id}</td>
                    <td className="px-6 py-4 text-left">{chapter.title}</td>
                    <td className="px-6 py-4 flex flex-row gap-2  justify-center">
                      <div className="flex flex-row items-center gap-2">
                        <button
                          type="button"
                          onClick={(e: any) => {
                            handleChangeChapterOpen(e, chapter.id);
                          }}
                          className="outline-none text-[#135991] duration-300"
                        >
                          {chapter.published ? (
                            <BiSolidShow size={22} />
                          ) : (
                            <BiSolidHide size={22} />
                          )}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="flex justify-center">
            <ImSpinner size={30} className=" animate-spin" />
          </div>
        )}
      </div>
    </div>
  );
}

export default EditAllowViewCourse;
