import { useEffect, useState } from "react";
import { fetchData } from "../../../../GlobalFunctions";
import { MenuItem, Select } from "@mui/material";
import {
  FaSchool,
  FaBook,
  FaComments,
  FaThumbsUp,
  FaCheckCircle,
  FaTimesCircle,
} from "react-icons/fa";
import api from "../../../../api";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { RiAlertFill } from "react-icons/ri";
import { FaCheck } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";

function AdminFeedback() {
  interface FeedbackData {
    feedback_id: string;
    name: string;
    relevance_of_content: string;
    satisfaction_level: string;
    other_comments: string;
    suggestions_for_improvement: string;
    issue_solved: string;
    school_name: string;
    school_id: string;
    course_name: string;
    course_id: string;
    chapter_name: string;
  }

  interface SchoolsData {
    name: string;
    id: string;
  }

  interface CoursesData {
    name: string;
    id: string;
  }

  interface ChaptersData {
    id: string;
    title: string;
  }

  const [feedbackData, setFeedbackData] = useState<FeedbackData[] | null>(null);

  const [filteredFeedback, setFilteredFeedback] = useState<
    FeedbackData[] | null
  >(null);
  const [schoolsData, setSchoolsData] = useState<SchoolsData[]>([]);
  const [selectedSchool, setSelectedSchool] = useState("all");
  const [coursesData, setCoursesData] = useState<CoursesData[] | null>(null);
  const [selectedCourse, setSelectedCourse] = useState("all");
  const [selectedCourseId, setSelectedCourseId] = useState("-1");
  const [chaptersData, setChaptersData] = useState<ChaptersData[]>([]);
  const [selectedChapter, setSelectedChapter] = useState("all");
  const [selectedIssueSolved, setSelectedIssueSolved] = useState("all");
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ADMIN_FEEDBACK}`,
      setFeedbackData
    );
  }, [refresh]);

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ALL_SCHOOLS}`,
      setSchoolsData
    );
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_COURSES_FILTER}`,
      setCoursesData
    );
  }, []);

  useEffect(() => {
    if (selectedCourseId === "-1") {
      setChaptersData([]);
      setSelectedChapter("all");
    } else {
      setSelectedChapter("all");
      fetchData(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_CHAPTER_FILTER}${selectedCourseId}`,
        setChaptersData
      );
    }
  }, [selectedCourseId]);

  useEffect(() => {
    if (feedbackData) {
      const filtered = feedbackData
        .filter((feedback) => {
          // Filter logic for school, course, and chapter
          const matchesSchool =
            selectedSchool === "all" || feedback.school_name === selectedSchool;
          const matchesCourse =
            selectedCourse === "all" || feedback.course_name === selectedCourse;
          const matchesChapter =
            selectedChapter === "all" ||
            feedback.chapter_name === selectedChapter;

          const matchesIssue =
            selectedIssueSolved === "all" ||
            feedback.issue_solved === selectedIssueSolved;

          return (
            matchesSchool && matchesCourse && matchesChapter && matchesIssue
          );
        })
        .sort((a, b) => a.name.localeCompare(b.name)); // Sort by name

      setFilteredFeedback(filtered);
    }
  }, [
    feedbackData,
    selectedCourse,
    selectedSchool,
    selectedChapter,
    selectedIssueSolved,
  ]);

  const handleChangeIssueStatus = async (e: any, feedback_id: string) => {
    e.preventDefault();

    try {
      const formData = new FormData();

      // formData.append("feedback_id", feeback_id);

      await api.post(
        `${process.env.REACT_APP_EDIT_ISSUE}${feedback_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setRefresh((prevRefresh: any) => prevRefresh + 1);
      toast.success("Issue status has been updated!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<{ message: string }>;
        const errorMessage =
          axiosError.response?.data?.message || axiosError.message;

        toast.error(
          `An error occurred while updating the Issue status: ${errorMessage}`,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          }
        );
      } else {
        toast.error(
          "An unexpected error occurred while updating the Issue status!",
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          }
        );
      }
      console.error("Error updating the Issue status:", error);
    }
  };

  return (
    <div>
      <div className="mb-5">
        <div className="grid md:grid-cols-4 gap-5">
          <div className="flex flex-col">
            <label className="pb-2 text-lg font-semibold">Course:</label>
            <Select
              value={selectedCourse}
              onChange={(e) => {
                const selectedCourseName = e.target.value;
                setSelectedCourse(selectedCourseName);

                // Find the corresponding course ID
                const selectedCourseData = coursesData?.find(
                  (course) => course.name === selectedCourseName
                );
                setSelectedCourseId(selectedCourseData?.id || "-1");
              }}
              className="border rounded-md"
            >
              <MenuItem key="all" value="all">
                <FaBook className="inline mr-2 mb-1" /> All
              </MenuItem>
              {coursesData &&
                coursesData.map((course, index) => (
                  <MenuItem key={index} value={course.name}>
                    <FaBook className="inline mr-2 mb-1" /> {course.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div className="flex flex-col">
            <label className="pb-2 text-lg font-semibold">Session:</label>
            <Select
              value={selectedChapter}
              onChange={(e) => setSelectedChapter(e.target.value)}
              className="border rounded-md"
            >
              <MenuItem key="all" value="all">
                <FaBook className="inline mr-2 mb-1" /> All
              </MenuItem>
              {chaptersData &&
                chaptersData.map((chapter, index) => (
                  <MenuItem key={index} value={chapter.title}>
                    <FaBook className="inline mr-2 mb-1" /> {chapter.title}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div className="flex flex-col">
            <label className="pb-2 text-lg font-semibold">School:</label>
            <Select
              value={selectedSchool}
              onChange={(e) => setSelectedSchool(e.target.value)}
              className="border rounded-md"
            >
              <MenuItem key="all" value="all">
                <FaSchool className="inline mr-2 mb-1" /> All
              </MenuItem>
              {schoolsData &&
                schoolsData.map((school, index) => (
                  <MenuItem key={index} value={school.name}>
                    <FaSchool className="inline mr-2 mb-1" /> {school.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div className="flex flex-col">
            <label className="pb-2 text-lg font-semibold">Status:</label>
            <Select
              value={selectedIssueSolved}
              onChange={(e) => setSelectedIssueSolved(e.target.value)}
              className="border rounded-md"
            >
              <MenuItem key="all" value="all">
                <RiAlertFill className="inline mr-2 mb-1" /> All
              </MenuItem>
              <MenuItem key="yes" value="yes">
                <FaCheck className="inline mr-2 mb-1" /> Solved
              </MenuItem>
              <MenuItem key="no" value="no">
                <IoClose className="inline mr-2 mb-1" /> Not Solved
              </MenuItem>
            </Select>
          </div>
        </div>
      </div>
      <div
        className="flex flex-col overflow-auto h-screen"
        style={{ maxHeight: "calc(100vh - 200px)" }}
      >
        {filteredFeedback?.map((feedback, index) => (
          <div
            key={index}
            className="flex flex-col w-full border-2 my-2 rounded-lg bg-white shadow-sm hover:shadow-lg transition-shadow duration-200"
          >
            <div className="flex flex-row justify-between text-gray-500 bg-gray-200 px-5 py-2">
              <div>Course: {feedback.course_name}</div>
              <div>{feedback.chapter_name}</div>
              <div>School: {feedback.school_name}</div>
            </div>
            <div className="px-5">
              <div className="font-bold text-xl mt-5">{feedback.name}</div>
              <div className="flex flex-row justify-between mt-5 text-gray-500">
                <div className=" flex flex-row items-center">
                  <FaThumbsUp className="inline mr-1" /> Relevancy :{" "}
                  {feedback.relevance_of_content}
                </div>
                <div className=" flex flex-row items-center">
                  <FaThumbsUp className="inline mr-1" /> Satisfaction :{" "}
                  {feedback.satisfaction_level}
                </div>
                <div className=" flex flex-row items-center">
                  {feedback.issue_solved === "yes" ? (
                    <FaCheckCircle className="inline mr-1 text-green-500" />
                  ) : (
                    <FaTimesCircle className="inline mr-1 text-red-500" />
                  )}
                  Status :{" "}
                  <input
                    type="checkbox"
                    id="videoexplainer"
                    className=" ml-2 h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                    checked={feedback.issue_solved === "yes"}
                    onChange={(e: any) =>
                      handleChangeIssueStatus(e, feedback.feedback_id)
                    }
                  />
                </div>
              </div>

              <div className="text-gray-600 mt-5">
                <div className=" flex flex-row items-center font-semibold">
                  <FaComments className="inline mr-1" /> Comment:
                </div>
                <div
                  className="course-description max-h-[100px] overflow-y-scroll p-2"
                  dangerouslySetInnerHTML={{
                    __html: feedback.other_comments as string,
                  }}
                />
              </div>
              <div className="text-gray-600 mt-5 pb-2">
                <div className=" flex flex-row items-center font-semibold">
                  <FaComments className="inline mr-1" /> Suggestions:
                </div>
                <div
                  className="course-description max-h-[100px] overflow-y-scroll p-2"
                  dangerouslySetInnerHTML={{
                    __html: feedback.suggestions_for_improvement as string,
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AdminFeedback;
