import { useEffect, useState } from "react";
import api from "../../../../api";
import { fetchData } from "../../../../GlobalFunctions";
import { Link, useLocation, useParams } from "react-router-dom";
import { IoChevronBackSharp } from "react-icons/io5";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

function StudentComment() {
  const { state } = useLocation();
  const { grade_name } = useParams();
  const { student_name } = useParams();

  const [studentId, setStudentId] = useState<string>(() => {
    const storedStudentId = window.localStorage.getItem("studentId") || "";
    return (state && state.student_id) || storedStudentId;
  });

  const [gradeId, setGradetId] = useState<string>(() => {
    const storedGradeId = window.localStorage.getItem("gradeId") || "";
    return (state && state.grade_id) || storedGradeId;
  });

  const [comment, setComment] = useState<string>("");

  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_STUDENT_COMMENT}${studentId}`,
      setComment
    );
  }, [refresh]);

  const handleUploadSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const formData = new FormData();

      formData.append("user_id", studentId);
      formData.append("comment", comment);

      await api.post(
        `${process.env.REACT_APP_EDIT_STUDENT_COMMENT}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setRefresh((prevRefresh: any) => prevRefresh + 1);
      toast.success("Comment has been updated!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<{ message: string }>;
        const errorMessage =
          axiosError.response?.data?.message || axiosError.message;

        toast.error(
          `An error occurred while updating the comment: ${errorMessage}`,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          }
        );
      } else {
        toast.error("An unexpected error occurred while updating the comment", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }
      console.error("Error updating the comment:", error);
    }
  };

  return (
    <div>
      <div className="relative pb-4">
        <div className="absolute">
          <Link
            to={`/teacher-grades/view-grade/${grade_name}`}
            state={{ grade_id: gradeId }}
          >
            <div className="flex flex-row items-center">
              <IoChevronBackSharp title="grade" />
              <p>Go Back</p>
            </div>
          </Link>
        </div>
      </div>
      <form onSubmit={handleUploadSubmit} className="my-10">
        <div className="flex flex-col">
          <label className="pb-2 text-[20px]">Student Name:</label>
          <input
            disabled
            type="text"
            value={student_name}
            className="border-[2px] text-black outline-none p-1 rounded-lg "
          />
        </div>
        <div className="flex flex-col mt-5">
          <label className="pb-2 text-[20px]">Comment:</label>
          <textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            className=" border-[2px] text-black  outline-none p-1 rounded-lg h-40 flex-grow"
          />
        </div>
        <div className=" flex justify-center  w-full mt-10 ">
          <button
            type="submit"
            className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default StudentComment;
