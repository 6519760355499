import axios, { AxiosError } from "axios";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import api from "../../../../api";
import { Link, useLocation, useParams } from "react-router-dom";
import { IoChevronBackSharp } from "react-icons/io5";
import { Select, MenuItem } from "@mui/material";
import JoditEditor from "jodit-react";
import config from "../Config";

function AddContent({ setRefresh }: any) {
  const [name, setName] = useState("");
  const { course_name } = useParams();
  const { chapter_name } = useParams();
  const { state } = useLocation();
  const [courseId, setCourseId] = useState<string>(() => {
    const storedCourseId = window.localStorage.getItem("courseId") || "";
    return (state && state.course_id) || storedCourseId;
  });
  const [chapterId, setChapterId] = useState<string>(() => {
    const storedChapterId = window.localStorage.getItem("chapterId") || "";
    return (state && state.chapter_id) || storedChapterId;
  });
  const [description, setDescription] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [link, setLink] = useState("");
  const [type, setType] = useState("file");
  const handleTypeChange = (e: any) => {
    setType(e.target.value);
    setName("");
    setFile(null);
    setLink("");
    setDescription("");
  };

  const editor = useRef(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleUploadSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const formData = new FormData();

      formData.append("type", type);
      formData.append("name", name);
      formData.append("description", description);

      if (type === "file") {
        if (file) {
          formData.append("file", file);
        }
      } else {
        formData.append("link", link);
      }

      await api.post(
        `${process.env.REACT_APP_CREATE_CONTENT}${chapterId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setRefresh((prevRefresh: any) => prevRefresh + 1);
      window.localStorage.setItem("courseId", courseId);
      window.localStorage.setItem("chapterId", chapterId);
      window.location.href = `/courses/edit-course/${course_name}/edit-chapter/${chapter_name}`;
      toast.success("Content has been created successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<{ message: string }>;
        const errorMessage =
          axiosError.response?.data?.message || axiosError.message;

        toast.error(
          `An error occurred while creating the Content: ${errorMessage}`,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          }
        );
      } else {
        toast.error("An unexpected error occurred while creating the Content", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }
      console.error("Error creating the Content:", error);
    }
  };

  return (
    <section
      className="overflow-hidden h-screen "
      style={{ maxHeight: "calc(100vh - 50px)" }}
    >
      <div className="flex flex-row items-center w-[210px]  gap-1">
        <Link
          state={{ course_id: courseId, chapter_id: chapterId }}
          className="px-2 hover:-ml-1 hover:pr-3 duration-200 rosunded-lg "
          to={`/courses/edit-course/${course_name}/edit-chapter/${chapter_name}`}
        >
          <IoChevronBackSharp title="Profile" size={17} />
        </Link>
        <p className="font-bold text-[20px]">Add Content:</p>
      </div>

      <form
        onSubmit={handleUploadSubmit}
        className="relative h-screen"
        style={{ maxHeight: "calc(100vh - 140px)" }}
      >
        <div
          className="p-4 flex flex-col gap-4 overflow-scroll h-screen "
          style={{ maxHeight: "calc(100vh - 190px)" }}
        >
          <div className=" flex flex-row content-center">
            <label className="pb-2 text-[20px] content-center mr-5">
              Type:
            </label>
            <Select value={type} onChange={handleTypeChange}>
              <MenuItem key="file" value="file">
                File
              </MenuItem>
              <MenuItem key="link" value="link">
                Link
              </MenuItem>
            </Select>
          </div>
          {type === "file" ? (
            <>
              <div className=" flex flex-col ">
                <label className="pb-2 text-[20px]">File Name:</label>
                <input
                  required
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className=" border-[2px] text-black  outline-none p-1 rounded-lg"
                />
              </div>
              <div className=" flex flex-col ">
                <label className="pb-2 text-[20px]">File:</label>
                <input required type="file" onChange={handleFileChange} />
              </div>
              <div className=" flex flex-col mb-10">
                <label className="pb-2 text-[20px]">Description:</label>
                {/* <ReactQuill
                  theme="snow"
                  value={description}
                  onChange={setDescription}
                /> */}
                <JoditEditor
                  ref={editor}
                  config={config}
                  value={description}
                  onChange={setDescription}
                />
              </div>
            </>
          ) : (
            <>
              <div className=" flex flex-col ">
                <label className="pb-2 text-[20px]">Link Name:</label>
                <input
                  required
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className=" border-[2px] text-black  outline-none p-1 rounded-lg"
                />
              </div>
              <div className=" flex flex-col ">
                <label className="pb-2 text-[20px]">Link:</label>
                <input
                  required
                  type="text"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                  className=" border-[2px] text-black  outline-none p-1 rounded-lg"
                />
              </div>
              <div className=" flex flex-col mb-10">
                <label className="pb-2 text-[20px]">Description:</label>
                <JoditEditor
                  ref={editor}
                  config={config}
                  value={description}
                  onChange={setDescription}
                />
              </div>
            </>
          )}
        </div>
        <div className=" flex justify-center absolute w-full bottom-0 ">
          <button
            type="submit"
            className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
          >
            Create Content
          </button>
        </div>
      </form>
    </section>
  );
}

export default AddContent;
