import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import NotFoundPage from "./NotFoundPage/NotFoundPage";
import HomeScreen from "./components/Dashboard/DashboardComponents/HomeScreen";
import { useEffect, useState } from "react";
import ProtectedRoute from "./Routes/ProtectedRoute";
import PublicRoute from "./Routes/PublicRoute";
import SignIn from "./components/SignIn/SignIn";
// import SignUp from "./components/SignUp/SignUp";
import Cookies from "js-cookie";
import { ImSpinner2 } from "react-icons/im";
import Profile from "./components/Dashboard/DashboardComponents/Profile/Profile";
import ProfileSettings from "./components/Dashboard/DashboardComponents/Profile/ProfileSettings";
import Courses from "./components/Dashboard/DashboardComponents/Courses/Courses";
import AddCourse from "./components/Dashboard/DashboardComponents/Admin/AdminCourses/AddCourse";
import EditCourse from "./components/Dashboard/DashboardComponents/Admin/AdminCourses/EditCourse";
import AddChapter from "./components/Dashboard/DashboardComponents/Admin/AdminChapters/AddChapter";
import EditChapter from "./components/Dashboard/DashboardComponents/Admin/AdminChapters/EditChapter";
import AddContent from "./components/Dashboard/DashboardComponents/Admin/AdminContent/AddContent";
import EditContent from "./components/Dashboard/DashboardComponents/Admin/AdminContent/EditContent";
import Roles from "./components/Dashboard/DashboardComponents/Admin/Roles/Roles";
import EditRole from "./components/Dashboard/DashboardComponents/Admin/Roles/EditRole";
import Users from "./components/Dashboard/DashboardComponents/Admin/Users/Users";
import ViewCourse from "./components/Dashboard/DashboardComponents/Student/ViewCourse/ViewCourse";
import ViewChapter from "./components/Dashboard/DashboardComponents/Student/ViewChapter/ViewChapter";
import Quiz from "./components/Dashboard/DashboardComponents/Student/ViewChapter/Quiz";
import Schools from "./components/Dashboard/DashboardComponents/Admin/Schools/Schools";
import EditSchool from "./components/Dashboard/DashboardComponents/Admin/Schools/EditSchool";
import Levels from "./components/Dashboard/DashboardComponents/Admin/Levels/Levels";
import EditLevel from "./components/Dashboard/DashboardComponents/Admin/Levels/EditLevel";
import EditContract from "./components/Dashboard/DashboardComponents/Admin/Contracts/EditContract";
import SALevels from "./components/Dashboard/DashboardComponents/SchoolAdmin/Levels/SALevels";
import SAEditLevel from "./components/Dashboard/DashboardComponents/SchoolAdmin/Levels/SAEditLevel";
import SASubscriptions from "./components/Dashboard/DashboardComponents/SchoolAdmin/Subscriptions/SASubscriptions";
import SAEditSubscription from "./components/Dashboard/DashboardComponents/SchoolAdmin/Subscriptions/SAEditSubscription";
import Plans from "./components/Dashboard/DashboardComponents/Admin/Plans/Plans";
import EditPlan from "./components/Dashboard/DashboardComponents/Admin/Plans/EditPlan";
import StudentDashboard from "./components/Dashboard/DashboardComponents/Student/StudentDashboard/StudentDashboard";
import StudentQuiz from "./components/Dashboard/DashboardComponents/Student/StudentQuiz/StudentQuiz";
import Chat from "./components/Dashboard/DashboardComponents/Chat/NewChat";
import TeacherGrades from "./components/Dashboard/DashboardComponents/Teacher/TeacherGrades/TeacherGrades";
import TeacherGradeStudents from "./components/Dashboard/DashboardComponents/Teacher/TeacherGradeStudents/TeacherGradeStudents";
import StudentComment from "./components/Dashboard/DashboardComponents/Teacher/StudentComment/StudentComment";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import AllowViewCourse from "./components/Dashboard/DashboardComponents/Admin/AllowViewCourse/AllowViewCourse";
import EditAllowViewCourse from "./components/Dashboard/DashboardComponents/Admin/AllowViewCourse/EditAllowViewCourse";
import AdminDashboard from "./components/Dashboard/DashboardComponents/Admin/AdminDashboard/AdminDashboard";
import Reviews from "./components/Dashboard/DashboardComponents/Admin/Reviews/Reviews";
import FeedbackCourses from "./components/Dashboard/DashboardComponents/Teacher/Feedback/FeedbackCourses";
import FeedbackSessions from "./components/Dashboard/DashboardComponents/Teacher/Feedback/FeedbackSessions";
import Feedback from "./components/Dashboard/DashboardComponents/Teacher/Feedback/Feedback";
import AdminFeedback from "./components/Dashboard/DashboardComponents/Admin/AdminFeedback/AdminFeedback";
import StudentQuizzes from "./components/Dashboard/DashboardComponents/Teacher/Student Quizzes/StudentQuizzes";

function App() {
  interface UserData {
    phone_number: string;
    profile_picture: string;
    user_email: string;
    user_id: string;
    user_name: string;
    user_token: string;
    roles: [string];
  }
  const [userID, setUserID] = useState<any | null>(Cookies.get("user_id"));
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(0);

  const [sideActive, setSideActive] = useState(false);

  const [mainSidebarOpen, setMainSidebarOpen] = useState(true);

  function changeSideActive() {
    setSideActive(!sideActive);
  }
  function changeMainSidebarOpen() {
    setMainSidebarOpen(!mainSidebarOpen);
  }
  const token = Cookies.get("access_token");

  useEffect(() => {
    if (userID === null) {
      setLoading(false);
    } else {
      const apiUrl = `${process.env.REACT_APP_API}${process.env.REACT_APP_USER_DETAILS}${userID}`;

      fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data: any) => {
          setUserData(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          localStorage.removeItem("user_id");
          localStorage.removeItem("user_name");
          localStorage.removeItem("user_email");
          localStorage.removeItem("courseId");
          localStorage.removeItem("chapterId");
        })
        .finally(() => setLoading(false));
    }
  }, [userID, refresh]);

  useEffect(() => {
    setUserID(localStorage.getItem("user_id"));
  }, [refresh]);

  if (loading) {
    return (
      <div className=" flex justify-center items-center w-screen h-screen">
        <ImSpinner2 size={25} className="animate-spin" />
      </div>
    );
  }

  return (
    <div className=" overflow-hidden">
      <BrowserRouter>
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route
              path="/"
              element={
                <Dashboard
                  mainSidebarOpen={mainSidebarOpen}
                  changeMainSidebarOpen={changeMainSidebarOpen}
                  sideActive={sideActive}
                  changeSideActive={changeSideActive}
                  userData={userData}
                  setUserData={setUserData}
                  setUserID={setUserID}
                />
              }
            >
              <Route path="" element={<HomeScreen />} />

              {userData?.roles.includes("student") && (
                <>
                  <Route path="/dashboard" element={<StudentDashboard />} />
                  <Route path="/quizzes" element={<StudentQuiz />} />
                </>
              )}
              <Route
                path="/chat"
                element={
                  <Chat
                    userData={userData}
                    sideActive={sideActive}
                    mainSidebarOpen={mainSidebarOpen}
                  />
                }
              />

              <Route
                path="/courses"
                element={<Courses userData={userData} />}
              />
              <Route
                path="/courses/view-course/:course_name"
                element={
                  <ViewCourse setRefresh={setRefresh} userData={userData} />
                }
              />
              <Route
                path="/courses/view-course/:course_name/view-chapter/:chapter_name"
                element={
                  <ViewChapter setRefresh={setRefresh} userData={userData} />
                }
              />
              <Route
                path="/courses/view-course/:course_name/view-chapter/:chapter_name/quiz"
                element={<Quiz userData={userData} />}
              />

              {userData?.roles.includes("superadmin") && (
                <>
                  {/* <Route path="/data-agents" element={<Agents />} />
                  <Route path="/tags" element={<Tag />} /> */}
                  <Route path="/dashboard" element={<AdminDashboard />} />
                  <Route
                    path="/courses/add-course"
                    element={<AddCourse setRefresh={setRefresh} />}
                  />
                  <Route
                    path="/courses/edit-course/:course_name"
                    element={<EditCourse setRefresh={setRefresh} />}
                  />
                  <Route
                    path="/courses/edit-course/:course_name/add-chapter"
                    element={<AddChapter setRefresh={setRefresh} />}
                  />
                  <Route
                    path="/courses/edit-course/:course_name/edit-chapter/:chapter_name"
                    element={<EditChapter setRefresh={setRefresh} />}
                  />
                  <Route
                    path="/courses/edit-course/:course_name/edit-chapter/:chapter_name/add-content"
                    element={<AddContent setRefresh={setRefresh} />}
                  />
                  <Route
                    path="/courses/edit-course/:course_name/edit-chapter/:chapter_name/edit-content/:content_name"
                    element={<EditContent setRefresh={setRefresh} />}
                  />
                  <Route
                    path="/roles"
                    element={
                      <Roles
                        setRefreshUserData={setRefresh}
                        refreshUserData={refresh}
                      />
                    }
                  />
                  <Route
                    path="/roles/edit_role/:role_id"
                    element={<EditRole setRefreshUserData={setRefresh} />}
                  />
                  <Route
                    path="/users"
                    element={
                      <Users
                        setRefreshUserData={setRefresh}
                        userData={userData}
                      />
                    }
                  />
                  <Route
                    path="/schools"
                    element={<Schools setRefreshData={setRefresh} />}
                  />
                  <Route
                    path="/schools/edit_school/:school_name"
                    element={<EditSchool setRefreshData={setRefresh} />}
                  />
                  <Route
                    path="/schools/edit_school/:school_name/edit_contract/:contract_name"
                    element={<EditContract setRefreshData={setRefresh} />}
                  />

                  <Route
                    path="/grades"
                    element={<Levels setRefreshData={setRefresh} />}
                  />
                  <Route
                    path="/grades/edit_grade/:grade_name"
                    element={<EditLevel setRefreshData={setRefresh} />}
                  />

                  <Route
                    path="/plans"
                    element={<Plans setRefreshData={setRefresh} />}
                  />
                  <Route
                    path="/plans/edit_plan/:plan_name"
                    element={<EditPlan setRefreshData={setRefresh} />}
                  />

                  <Route
                    path="/chapters_published_status"
                    element={<AllowViewCourse setRefreshData={setRefresh} />}
                  />
                  <Route
                    path="/chapters_published_status/edit_status/:course_name"
                    element={
                      <EditAllowViewCourse setRefreshData={setRefresh} />
                    }
                  />
                  <Route path="/reviews" element={<Reviews />} />
                  <Route path="/feedback" element={<AdminFeedback />} />
                </>
              )}

              {userData?.roles?.includes("schooladmin") && (
                <Route path="/dashboard" element={<StudentDashboard />} />
              )}

              {(userData?.roles?.includes("schooladmin") ||
                userData?.roles?.includes("superadmin")) && (
                <>
                  <Route
                    path="/users"
                    element={
                      <Users
                        setRefreshUserData={setRefresh}
                        userData={userData}
                      />
                    }
                  />
                  <Route
                    path="/school_grades"
                    element={
                      <SALevels
                        setRefreshUserData={setRefresh}
                        userData={userData}
                      />
                    }
                  />
                  <Route
                    path="/school_grades/edit_grade/:grade_name"
                    element={
                      <SAEditLevel
                        setRefreshUserData={setRefresh}
                        userData={userData}
                      />
                    }
                  />
                  <Route
                    path="/school_subscriptions"
                    element={
                      <SASubscriptions
                        setRefreshUserData={setRefresh}
                        userData={userData}
                      />
                    }
                  />
                  <Route
                    path="/school_subscriptions/edit_subscription/:subscription_name"
                    element={
                      <SAEditSubscription
                        setRefreshUserData={setRefresh}
                        userData={userData}
                      />
                    }
                  />
                </>
              )}
              {userData?.roles?.includes("teacher") && (
                <>
                  <Route
                    path="/teacher-grades"
                    element={
                      <TeacherGrades
                        setRefreshUserData={setRefresh}
                        userData={userData}
                      />
                    }
                  />
                  <Route
                    path="/teacher-grades/view-grade/:grade_name"
                    element={
                      <TeacherGradeStudents
                        setRefreshUserData={setRefresh}
                        userData={userData}
                      />
                    }
                  />
                  <Route
                    path="/teacher-grades/view-grade/:grade_name/student-comment/:student_name"
                    element={<StudentComment />}
                  />
                  <Route path="/dashboard" element={<StudentDashboard />} />
                  <Route path="/student-quizzes" element={<StudentQuizzes />} />
                  <Route
                    path="/feedback-courses"
                    element={<FeedbackCourses />}
                  />
                  <Route
                    path="/feedback-courses/view-course/:course_name"
                    element={<FeedbackSessions />}
                  />
                  <Route
                    path="/feedback-courses/view-course/:course_name/view-session/:chapter_title"
                    element={<Feedback />}
                  />
                </>
              )}

              <Route
                path="/profile"
                element={<Profile userData={userData} />}
              />
              <Route
                path="/profile/profile-settings"
                element={
                  <ProfileSettings
                    userData={userData}
                    setRefresh={setRefresh}
                  />
                }
              />
            </Route>
          </Route>

          <Route element={<PublicRoute />}>
            <Route path="/signin" element={<SignIn setUserID={setUserID} />} />
            <Route
              path="/reset-password/:token"
              element={<ChangePassword setUserID={setUserID} />}
            />
            {/* <Route path="/signup" element={<SignUp />} /> */}
          </Route>
          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
