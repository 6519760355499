import { useState, useEffect, useRef } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { IoChevronBackSharp } from "react-icons/io5";
import { ImSpinner } from "react-icons/im";
import api from "../../../../api";
import JoditEditor from "jodit-react";
import { toast } from "react-toastify";
import axios, { AxiosError } from "axios";
import config from "../../Admin/Config";
import { MenuItem, Select } from "@mui/material";

function Feedback() {
  interface FeedbackData {
    relevance: string;
    satisfaction: string;
    other_comments: string;
    suggestions: string;
    issue_solved: string;
  }
  const editor = useRef(null);

  const { course_name, chapter_title } = useParams();
  const [feedbackData, setFeedbackData] = useState<FeedbackData>();

  const [loading1, setLoading1] = useState(true);
  const { state } = useLocation();

  const [courseId, setCourseId] = useState<string>(() => {
    const storedCourseId = window.localStorage.getItem("courseId") || "";
    return (state && state.course_id) || storedCourseId;
  });
  const [gradeName, setGradeName] = useState<string>(() => {
    const storedGradeName = window.localStorage.getItem("gradeName") || "";
    return (state && state.grade_name) || storedGradeName;
  });
  const [chapterId, setChapterId] = useState<string>(() => {
    const storedChapterId = window.localStorage.getItem("chapterId") || "";
    return (state && state.chapter_id) || storedChapterId;
  });
  const user_id = localStorage.getItem("user_id") || "default_user";
  const [relevance, setRelevance] = useState<string>("select an option");
  const [satisfaction, setSatisfaction] = useState<string>("select an option");
  const [otherComments, setOtherComments] = useState<string>("");
  const [suggestions, setSuggestions] = useState<string>("");
  // const [issueSolved, setIssueSolved] = useState<string>("select an option");

  useEffect(() => {
    async function fetchData1() {
      try {
        const formData = new FormData();
        formData.append("user_id", user_id);
        formData.append("chapter_id", chapterId);

        const response = await api.post(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_FEEDBACK}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const data = response.data;
        setFeedbackData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData1();
    setLoading1(false);
  }, [user_id]);

  useEffect(() => {
    setRelevance(feedbackData?.relevance ?? "select an option");
    setSatisfaction(feedbackData?.satisfaction ?? "select an option");
    // setIssueSolved(feedbackData?.issue_solved ?? "select an option");
    setOtherComments(feedbackData?.other_comments ?? "");
    setSuggestions(feedbackData?.suggestions ?? "");
  }, [feedbackData]);

  const handleUploadSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (relevance === "select an option") {
      return toast.error("Select Relevance Level!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    }

    if (satisfaction === "select an option") {
      return toast.error("Select Satisfaction Level!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    }

    // if (issueSolved === "select an option") {
    //   return toast.error("Select Issue Solved!", {
    //     position: "top-center",
    //     autoClose: 2000,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: false,
    //     theme: "dark",
    //   });
    // }

    try {
      const formData = new FormData();
      formData.append("user_id", user_id);
      formData.append("relevance", relevance);
      formData.append("satisfaction", satisfaction);
      formData.append("other_comments", otherComments);
      formData.append("suggestions", suggestions);
      // formData.append("issue_solved", issueSolved);

      await api.post(
        `${process.env.REACT_APP_EDIT_FEEDBACK}${chapterId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      window.localStorage.setItem("courseId", courseId);

      toast.success("Feedback has been edited successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<{ message: string }>;
        const errorMessage =
          axiosError.response?.data?.message || axiosError.message;

        toast.error(
          `An error occurred while editing the Feedback: ${errorMessage}`,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          }
        );
      } else {
        toast.error("An unexpected error occurred while editing the Feedback", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }
      console.error("Error editing the Feedback:", error);
    }
  };

  if (loading1) {
    return (
      <div className="flex justify-center mt-10">
        <ImSpinner size={30} className=" animate-spin" />
      </div>
    );
  }

  return (
    <section
      className="overflow-hidden h-screen "
      style={{ maxHeight: "calc(100vh - 50px)" }}
    >
      <div className="flex flex-row items-center gap-1 mb-2 w-full">
        <Link
          className="px-2 hover:-ml-1 hover:pr-3 duration-200 rosunded-lg "
          to={`/feedback-courses/view-course/${course_name}`}
          state={{
            course_id: courseId,
            grade_name: gradeName,
          }}
        >
          <IoChevronBackSharp title="Profile" size={17} />
        </Link>
        <p className="font-bold text-[20px] w-full">{chapter_title}</p>
      </div>

      <div className="relative h-screen" style={{ maxHeight: "calc(100vh )" }}>
        <div className="p-4 flex flex-col gap-4 overflow-scroll h-screen">
          <form
            onSubmit={handleUploadSubmit}
            className="relative h-screen"
            style={{ maxHeight: "calc(100vh - 180px)" }}
          >
            <div
              className="p-4 flex flex-col gap-4 overflow-scroll h-screen "
              style={{ maxHeight: "calc(100vh - 230px)" }}
            >
              <div className="md:grid grid-cols-2 md:gap-2">
                <div className="flex flex-row col-span-1 items-center gap-2">
                  <label className="text-[20px]">Relevance:</label>
                  <Select
                    value={relevance}
                    onChange={(e) => setRelevance(e.target.value)}
                    className=" h-12 w-80"
                  >
                    <MenuItem
                      disabled={true}
                      id="tone-div"
                      key="select an option"
                      value="select an option"
                    >
                      Select an Option
                    </MenuItem>
                    <MenuItem
                      id="tone-div"
                      key="highly relevant"
                      value="highly relevant"
                    >
                      Highly Relevant
                    </MenuItem>
                    <MenuItem id="tone-div" key="relevant" value="relevant">
                      Relevant
                    </MenuItem>
                    <MenuItem
                      id="tone-div"
                      key="somewhat relevant"
                      value="somewhat relevant"
                    >
                      Somewhat Relevant
                    </MenuItem>
                    <MenuItem
                      id="tone-div"
                      key="not relevant"
                      value="not relevant"
                    >
                      Not Relevant
                    </MenuItem>
                  </Select>
                </div>
                <div className="flex flex-row col-span-1 items-center gap-2 max-md:mt-10">
                  <label className="text-[20px]">Satisfaction:</label>
                  <Select
                    value={satisfaction}
                    onChange={(e) => setSatisfaction(e.target.value)}
                    className=" h-12 w-80"
                  >
                    <MenuItem
                      disabled={true}
                      id="tone-div"
                      key="select an option"
                      value="select an option"
                    >
                      Select an Option
                    </MenuItem>
                    <MenuItem
                      id="tone-div"
                      key="very satisfied"
                      value="very satisfied"
                    >
                      Very Satisfied
                    </MenuItem>
                    <MenuItem id="tone-div" key="satisfied" value="satisfied">
                      Satisfied
                    </MenuItem>
                    <MenuItem id="tone-div" key="neutral" value="neutral">
                      Neutral
                    </MenuItem>
                    <MenuItem
                      id="tone-div"
                      key="dissatisfied"
                      value="dissatisfied"
                    >
                      Dissatisfied
                    </MenuItem>
                    <MenuItem
                      id="tone-div"
                      key="very dissatisfied"
                      value="very dissatisfied"
                    >
                      Very Dissatisfied
                    </MenuItem>
                  </Select>
                </div>
              </div>
              <div>
                {/* <div className="flex flex-row col-span-1 items-center gap-2 mt-10">
                  <label className="text-[20px]">Issue Solved:</label>
                  <Select
                    value={issueSolved}
                    onChange={(e) => setIssueSolved(e.target.value)}
                    className=" h-12 w-80"
                  >
                    <MenuItem
                      disabled={true}
                      id="tone-div"
                      key="select an option"
                      value="select an option"
                    >
                      Select an Option
                    </MenuItem>
                    <MenuItem id="tone-div" key="yes" value="yes">
                      Yes
                    </MenuItem>
                    <MenuItem id="tone-div" key="no" value="no">
                      No
                    </MenuItem>
                  </Select>
                </div> */}
              </div>
              <div className=" flex flex-col mt-10">
                <label className="pb-2 text-[22px] font-semibold">
                  Comment:
                </label>
                <JoditEditor
                  ref={editor}
                  config={config}
                  value={otherComments}
                  onChange={setOtherComments}
                />
              </div>
              <div className=" flex flex-col mt-10">
                <label className="pb-2 text-[22px] font-semibold">
                  Suggestions:
                </label>
                <JoditEditor
                  ref={editor}
                  config={config}
                  value={suggestions}
                  onChange={setSuggestions}
                />
              </div>
            </div>

            <div className=" flex justify-center absolute w-full bottom-0 ">
              <button
                type="submit"
                className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Feedback;
