import { useEffect, useState } from "react";
import { fetchData } from "../../../../GlobalFunctions";
import { MenuItem, Select } from "@mui/material";
import { FaBook, FaRegStar, FaSchool, FaStar } from "react-icons/fa6";

function Reviews() {
  interface ReviewsData {
    stars: number;
    comment: string;
    name: string;
    school_name: string;
    school_id: string;
    course_name: string;
    course_id: string;
  }

  interface SchoolsData {
    name: string;
    id: string;
  }

  interface CoursesData {
    name: string;
    id: string;
  }

  const [reviewsData, setReviewsData] = useState<ReviewsData[] | null>(null);

  const [filteredReviews, setFilteredReviews] = useState<ReviewsData[] | null>(
    null
  );
  const [schoolsData, setSchoolsData] = useState<SchoolsData[]>([]);
  const [selectedSchool, setSelectedSchool] = useState("all");
  const [coursesData, setCoursesData] = useState<CoursesData[] | null>(null);

  const [selectedCourse, setSelectedCourse] = useState("all");

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ALL_REVIEWS}`,
      setReviewsData
    );
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ALL_SCHOOLS}`,
      setSchoolsData
    );
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_ALL_COURSES}`,
      setCoursesData
    );
  }, []);

  useEffect(() => {
    if (reviewsData) {
      if (selectedSchool === "all" && selectedCourse === "all") {
        const filtered = reviewsData.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setFilteredReviews(filtered);
      } else if (selectedSchool === "all") {
        const filtered = reviewsData
          .filter((review) =>
            selectedCourse ? review.course_name === selectedCourse : true
          )
          .sort((a, b) => a.name.localeCompare(b.name));

        setFilteredReviews(filtered);
      } else if (selectedCourse === "all") {
        const filtered = reviewsData
          .filter((review) =>
            selectedSchool ? review.school_name === selectedSchool : true
          )
          .sort((a, b) => a.name.localeCompare(b.name));

        setFilteredReviews(filtered);
      } else {
        const filtered = reviewsData
          .filter(
            (review) =>
              (selectedCourse ? review.course_name === selectedCourse : true) &&
              (selectedSchool ? review.school_name === selectedSchool : true)
          )
          .sort((a, b) => a.name.localeCompare(b.name));

        setFilteredReviews(filtered);
      }
    }
  }, [reviewsData, selectedCourse, selectedSchool]);

  return (
    <div>
      <div>
        <div className="grid grid-cols-3 gap-5 mb-5">
          <div className="flex flex-col">
            <label className="pb-2 text-lg font-semibold">Course:</label>
            <Select
              value={selectedCourse}
              onChange={(e) => setSelectedCourse(e.target.value)}
              className="border rounded-md"
            >
              <MenuItem key="all" value="all">
                <FaBook className="inline mr-2" /> All
              </MenuItem>
              {coursesData &&
                coursesData.map((course, index) => (
                  <MenuItem key={index} value={course.name}>
                    <FaBook className="inline mr-2" /> {course.name}{" "}
                  </MenuItem>
                ))}
            </Select>
          </div>

          <div className="flex flex-col">
            <label className="pb-2 text-lg font-semibold">School:</label>
            <Select
              value={selectedSchool}
              onChange={(e) => setSelectedSchool(e.target.value)}
              className="border rounded-md"
            >
              <MenuItem key="all" value="all">
                <FaSchool className="inline mr-2" /> All
              </MenuItem>
              {schoolsData &&
                schoolsData.map((school, index) => (
                  <MenuItem key={index} value={school.name}>
                    <FaSchool className="inline mr-2" /> {school.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
        </div>
      </div>
      <div
        className="flex flex-col overflow-scroll h-screen"
        style={{ maxHeight: "calc(100vh - 200px)" }}
      >
        {filteredReviews?.map((review, index) => (
          <div className="flex flex-col w-full p-5 border-2 my-2 rounded-lg">
            <div>{review.name}</div>
            <div className="  flex flex-row gap-2 w-fit mt-2 rounded ">
              {review.stars > 0 ? (
                <FaStar color="gold" title="Profile" size={20} />
              ) : (
                <FaRegStar color="gold" title="Profile" size={20} />
              )}

              {review.stars > 1 ? (
                <FaStar color="gold" title="Profile" size={20} />
              ) : (
                <FaRegStar color="gold" title="Profile" size={20} />
              )}
              {review.stars > 2 ? (
                <FaStar color="gold" title="Profile" size={20} />
              ) : (
                <FaRegStar color="gold" title="Profile" size={20} />
              )}
              {review.stars > 3 ? (
                <FaStar color="gold" title="Profile" size={20} />
              ) : (
                <FaRegStar color="gold" title="Profile" size={20} />
              )}
              {review.stars > 4 ? (
                <FaStar color="gold" title="Profile" size={20} />
              ) : (
                <FaRegStar color="gold" title="Profile" size={20} />
              )}
            </div>
            <div className="mt-5">{review.comment}</div>
            <div className="flex flex-row justify-between mt-5 text-gray-500">
              <div>Course: {review.course_name}</div>
              <div>School: {review.school_name}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Reviews;
